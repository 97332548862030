import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {OAuthService} from "angular-oauth2-oidc";
import {Ausfueller} from 'app/form-viewer/model/Ausfueller';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {CookieService} from 'ngx-cookie-service';
import {MessageService} from 'primeng/api';
import {VerwaltbarerAusfuellerInstitutionDto} from '../../benutzerverwaltung/model/VerwaltbarerAusfuellerInstitutionDto';
import {SichtbareInstitutionDto} from '../../portal-selector/model/SichtbareInstitutionDto';
import {AlertService} from '../../shared/alert/service/AlertService';
import {Institution} from '../../shared/model/Institution';
import {setInstitution} from '../../store/action/institution.actions';
import {setRecherchekomplex} from '../../store/action/recherchekomplex.actions';
import {Berechtigung} from '../model/Berechtigung';
import {FindAusfuellerService} from "./FindAusfuellerService";

const debug = require('debug')('service:UserService');

@Injectable()
export class UserService {

	public _sichtbareInstitutionen: SichtbareInstitutionDto[] = [];
	public loaded: boolean;
	public authenticating: boolean = false;
	@BlockUI() blockUI: NgBlockUI;
	private ausfueller: Ausfueller;
	private ausfuellerItem: string;
	private currentInstitutionItem: string;
	private currentInstitution: VerwaltbarerAusfuellerInstitutionDto;
	private _aktuelleInstitution: SichtbareInstitutionDto;
	private logoutInProgress: boolean = false;

	constructor(private http: HttpClient,
				private store: Store,
				private router: Router,
				private alertService: AlertService,
				private route: ActivatedRoute,
				private httpClient: HttpClient,
				private messageService: MessageService,
				private oauthService: OAuthService,
				private cookieService: CookieService,
				private findAusfuellerService: FindAusfuellerService,
	) {
		// this.oauthService.events.subscribe((e: OAuthEvent) => {
		// 	if (e.type==='discovery_document_loaded') {
		// 		console.log(oauthService.getAccessToken())
		// 	}
		//
		// })
	}

	clearData() {
		console.log('CLEAR DATA');
		this._sichtbareInstitutionen = [];
		this.loaded = false;
		this.ausfueller = null;
		this.ausfuellerItem = null;
		this.currentInstitutionItem = null;
		this.currentInstitution = null;
		this._aktuelleInstitution = null;
	}

	authenticate(): Promise<Ausfueller> {
		console.log('authenticate...');
		return this.http.get('admin/user', {})
				   .toPromise()
				   .then(response => {
					   this.ausfueller = new Ausfueller(response as Ausfueller);
					   // console.log(response as Ausfueller)
					   console.log(this.ausfueller);
					   console.log(this.ausfueller.enabled);

					   if (this.ausfueller.enabled === false) {
						   this.blockUI.start('Sie werden ausgeloggt...');
						   this.logoutNichtRegistriert(this.ausfueller.email);
					   } else if (this.ausfueller.archiviert === true) {
						   this.blockUI.start('Sie werden ausgeloggt...');
						   this.logoutArchiviert(this.ausfueller.email);
					   } else {
						   localStorage.setItem('ausfueller', '' + this.encodeB64(JSON.stringify(this.ausfueller)));
						   console.log('authenticate...DONE');
						   return this.ausfueller;
					   }
				   })
				   .catch(this.alertService.handleHttpError);
	}

	isAlive(): void {
		try {
			console.log(`isAlive....`);
			console.log(this.ausfueller.email);

			if (this.ausfueller.email.includes('@opitz-consulting.com') || this.ausfueller.email.includes('@juve.de')) {
				var startTime = performance.now();
				console.log('isAlive...');
				this.httpClient.get<boolean>('/view/ausfueller/alive/').toPromise()
					.then(json => this.showDurationMsg(performance.now() - startTime))
					.catch(this.alertService.handleHttpError)
				;
			} else {
				var startTime = performance.now();
				console.log('isAlive...');
				this.httpClient.get<boolean>('/view/ausfueller/alive/').toPromise()
					.then(json => console.log(`Call to isAlive took ${performance.now() - startTime} ms`))
					.catch(this.alertService.handleHttpError)
				;
			}
		} catch (exception) {
			console.log(exception);
			console.log('logout isAlive');
			this.logout();
		}
		finally {
		}
	}

	isAliveInitPage(): void {
		try {
			console.log(`isAlive....`);
			this.httpClient.get<boolean>('/view/ausfueller/alive/');
		} catch (exception) {
			console.log(exception);
			console.log('logout isAlive');
			this.logout();
		}
		finally {
		}
	}

	showDurationMsg(durationAVG) {

		durationAVG = durationAVG.toFixed(0);

		if (durationAVG > 2500) {
			this.showIsAliveError(durationAVG);
		} else if (durationAVG > 1000) {
			this.showIsAliveWarn(durationAVG);
		} else {
			this.showIsAliveInfo(durationAVG);
		}
	}

	showIsAliveError(durationAVG) {
		this.messageService.add({
									severity: 'error', life: 20000,
									summary: `Ihre Internetverbindung ist zu langsam (Messung ${durationAVG} ms)`
								});

	}

	showIsAliveWarn(durationAVG) {
		this.messageService.add({
									severity: 'warn', life: 20000,
									summary: `Ihre Internetverbindung ist etwas zu langsam (Messung ${durationAVG} ms)`
								});
	}

	showIsAliveInfo(durationAVG) {
		this.messageService.add({
									severity: 'info', life: 5000,
									summary: `Ihre Internetverbindung ist schnell (Messung ${durationAVG} ms)`
								});
	}

	generatePwdForAll() {
		try {
			return this.httpClient.get('/view/ausfueller/new_pwd_for_all/')
					   .toPromise()
					   .catch(this.alertService.handleHttpError);
		} catch (exception) {
			console.log(exception);
		}
		finally {
		}
	}

	// logout() {
	// 	console.log("logout()")
	// 	this.http.get('logout', {}).toPromise()
	// 		.then()
	// 		.catch(() => console.log('error on logout'));
	// }

	clearStorage() {
		console.log('CLEAR STORAGE');
		this.ausfueller = null;
		this.store.dispatch(setInstitution(null));
		this.store.dispatch(setRecherchekomplex(null));
		sessionStorage.clear();
		localStorage.removeItem('institution');
		localStorage.removeItem('recherchekomplex');
		localStorage.removeItem('ausfueller');
		localStorage.removeItem('aktuelleInstitution');
		localStorage.removeItem('sichtbareInstitutionen');
		// localStorage.clear();
	}

	setSichtbareInstitutionen(sichtbareInstitutionen) {
		let encode = this.encodeB64(JSON.stringify(sichtbareInstitutionen));
		// console.log("encode  " + encode)
		localStorage.setItem('sichtbareInstitutionen', encode);
		// console.log(this.getAktuelleInstitution())
	}

	getSichtbareInstitutionen() {
		let aktuelleInstitutionenItem = localStorage.getItem('sichtbareInstitutionen');
		// console.log(aktuelleInstitutionenItem)
		if (aktuelleInstitutionenItem && aktuelleInstitutionenItem.length != 0) {
			let decode = JSON.parse(this.decodeB64(aktuelleInstitutionenItem));
			// console.log(decode)

			let tmpList = [];

			for (const tmpListElement of decode) {
				tmpList.push(new SichtbareInstitutionDto(tmpListElement));
			}

			return tmpList;
		}
		return null;
	}

	setAktuelleInstitution(institution) {
		let encode = this.encodeB64(JSON.stringify(institution));
		localStorage.setItem('aktuelleInstitution', encode);
	}

	getAktuelleInstitution() {
		let aktuelleInstitutionItem = localStorage.getItem('aktuelleInstitution');
		let ausfueller: Ausfueller;
		if (aktuelleInstitutionItem && aktuelleInstitutionItem.length != 0) {
			let decode = JSON.parse(this.decodeB64(aktuelleInstitutionItem));
			return new Institution(decode);
		} else {
			if (!this.getSichtbareInstitutionen() || this.getSichtbareInstitutionen().length == 0) {
				// console.log("UserService - LADE sichtbareInstitution by Service")
				this.collectSichtbareInstitutionen();
			} else {
				// console.log("UserService - LADE sichtbareInstitution by LIST")
				this._sichtbareInstitutionen = this.getSichtbareInstitutionen();
				this.extractCurrentInstitute(false);

				aktuelleInstitutionItem = localStorage.getItem('aktuelleInstitution');
				if (aktuelleInstitutionItem && aktuelleInstitutionItem.length != 0) {
					let decode = JSON.parse(this.decodeB64(aktuelleInstitutionItem));
					return new Institution(decode);
				}

				this.blockUI.stop();
			}
		}
		return null;
	}

	getAktuelleInstitutionAsSichtbareInstitutionDto() {
		if (!this._aktuelleInstitution) {
			if (!this.getSichtbareInstitutionen() || this.getSichtbareInstitutionen().length == 0) {
				// console.log("UserService - LADE sichtbareInstitution by Service")
				this.collectSichtbareInstitutionen();
			} else {
				// console.log("UserService - LADE sichtbareInstitution by LIST")
				this._sichtbareInstitutionen = this.getSichtbareInstitutionen();
				this.extractCurrentInstitute(false);
				this.blockUI.stop();
			}
		}
		if (!this._aktuelleInstitution) {
			return null;
		} else {
			return this._aktuelleInstitution;
		}
	}

	getAusfuellerWithoutMessage(): Ausfueller {
		return this.findAusfuellerService.findAusfueller(this.noUserFound, this.clearData);
	}

	getAusfuellerVarValue(): Ausfueller {
		return this.ausfueller;
	}

	getAusfueller(): Ausfueller {
		// console.log("getAusfueller")
		return this.findAusfuellerService.findAusfueller(this.noUserFound, this.clearData);
	}

	isAutor(): boolean {
		let ausfueller = this.getAusfueller();
		return ausfueller && ausfueller.authorityStrings.includes('ROLE_FRAGEBOGENAUTOR');
	}

	isEnabled(): boolean {
		let ausfueller = this.getAusfueller();
		return ausfueller && ausfueller.enabled === true;
	}

	getRightsForCurrentInstitute() {
		let tmpBerechtigungen: Berechtigung[] = [];

		let tmpInst = this.getAktuelleInstitution();
		for (const berechtigung of this.getAusfueller().berechtigungen) {
			if (berechtigung.institution.id === tmpInst.id) {
				tmpBerechtigungen.push(berechtigung);
			}
		}

		// console.log(tmpBerechtigungen)
		return tmpBerechtigungen;
	}

	/*findAusfueller(showMessage): Ausfueller {

		if (!this.ausfueller) {
			console.log('Find Ausfueller');

			try {
				this.ausfuellerItem = localStorage.getItem('ausfueller');
				if (this.ausfuellerItem && this.ausfuellerItem.trim()) {
					this.ausfuellerItem = this.decodeB64(this.ausfuellerItem);
					this.ausfueller = new Ausfueller(JSON.parse(this.ausfuellerItem));
					if (this.ausfueller == null) {
						return this.noUserFound(skipRedirect);
					}
				} else {
					return this.noUserFound(skipRedirect);
				}
			} catch (exception) {
				console.clear();
				console.log(exception);
				console.log('Ihre Benutzerdaten konnten nicht fehlerfrei geladen werden. ' +
								'Bitte führen Sie folgende Schritte aus und kontaktieren Sie einen Adminstrator: \n\n' +
								'1. F12 drücken\n' +
								'2. Console öffnen\n' +
								'3. Kopieren Sie alle Ausgaben der Konsole und leiten Sie diese als Text-Datei an einen Administrator weiter');

				console.log('ADMINLOG-START -->');
				console.log(this.ausfuellerItem);
				console.log('<--ADMINLOG-END');
				this.clearData();
				return null;
			}
		}
		return this.ausfueller;

	}*/

	authenticatingFalse() {
		this.authenticating = false;
	}

	findCurrentInstitute(): VerwaltbarerAusfuellerInstitutionDto {
		try {
			this.currentInstitutionItem = localStorage.getItem('ausfueller');
			if (this.currentInstitutionItem && this.currentInstitutionItem.trim()) {
				this.currentInstitutionItem = this.decodeB64(this.currentInstitutionItem);
				this.currentInstitution = new VerwaltbarerAusfuellerInstitutionDto(JSON.parse(this.ausfuellerItem));
				if (this.ausfueller == null) {
					// console.log("ausfueller ist leer");
					// confirm("Bitte wählen Sie ein Unternehmen aus, in dem Sie arbieten möchten")
				} else {
				}
			} else {
			}
			return this.currentInstitution;
		} catch (exception) {

			return null;
		}
	}

	isAuthenticated(): boolean {
		return !!this.ausfueller;
	}

	encodeB64(str): string {
		// return btoa(unescape(JSON.stringify(str)))
		return btoa(unescape(encodeURIComponent(str)));
	}

	decodeB64(str): string {
		return decodeURIComponent(escape(atob(str)));
	}

	collectSichtbareInstitutionen() {
		this.blockUI.start('Daten werden verarbeitet...');
		this.httpClient.get<SichtbareInstitutionDto[]>(`/content/portal-allgemein`)
			.toPromise()
			.catch(this.alertService.handleHttpError)
			.then(json => SichtbareInstitutionDto.buildList(json))
			.then(dtos => this._sichtbareInstitutionen = dtos)
			.then(() => {
				this.extractCurrentInstitute(true);
			}).finally(this.blockUI.stop);
	}

	logout() {
		try {
			if (this.logoutInProgress) {
				return;
			}
			this.logoutInProgress = true;
			this.blockUI.start('Sie werden ausgeloggt...');
			this.entsperrenAndLogout();
			setTimeout(() => {
				this.kcLogout();
				this.logoutInProgress = false;
			}, 500);
		} catch (ignore) {

		}
		finally {
			setTimeout(() => {
				this.logoutInProgress = false;
			}, 1000);
		}
	}

	logoutEditor() {
		try {
			if (this.logoutInProgress) {
				return;
			}
			this.blockUI.start('Sie werden ausgeloggt...');
			this.logoutInProgress = true;
			setTimeout(() => {
				this.kcLogout();
				this.logoutInProgress = false;
			}, 500);
		} catch (ignore) {

		}
		finally {
			setTimeout(() => {
				this.logoutInProgress = false;
			}, 1000);
		}
	}

	logoutCustomMessage(msg) {
		try {
			if (this.logoutInProgress) {
				return;
			}
			this.logoutInProgress = true;
			this.blockUI.start(msg);
			this.entsperrenForUser();
			setTimeout(() => {
				this.kcLogout();
				this.logoutInProgress = false;
			}, 500);
		} catch (ignore) {

		}
		finally {
			setTimeout(() => {
				this.logoutInProgress = false;
			}, 1000);
		}
	}

	entsperrenForUser() {
		try {
			console.log('entsperren');
			if (this.ausfueller != null) {
				return this.http.post('/api/abschnitt/sperre/entsperren_for_user/' + this.ausfueller.id, null)
						   .toPromise().then(() => {
					})
						   .catch(this.alertService.handleHttpError).finally();
			}
		} catch (ignore) {

		}
	}

	entsperrenAndLogout() {
		try {
			console.log('entsperren und Logout');
			if (this.ausfueller != null) {
				return this.http.post('/api/abschnitt/sperre/entsperren_for_user/' + this.ausfueller.id, null)
						   .toPromise().then(() => {
					})
						   .catch().finally();
			}
		} catch (ignore) {

		}
	}

	kcLogout() {
		this.blockUI.start('Sie werden ausgeloggt...');
		this.oauthService.logOut()

		try {
			this.clearData();
		} catch (e) {}
		try {
			this.clearStorage();
		} catch (e) {}

		console.log('CLEAR TOKEN');
	}

	logout504() {
		if (this.logoutInProgress) {
			return true;
		}
		try {
			this.logoutInProgress = true;
			alert('System ist aktuell nicht erreichbar...');
			console.log('LOGOUT...');
			this.kcLogout();
			this.logoutInProgress = false;
		} catch (exception) {
			this.logoutInProgress = false;
		}
		finally {
		}
	}

	logout401() {
		if (this.logoutInProgress) {
			return true;
		}
		try {
			this.logoutInProgress = true;
			this.blockUI.start('Es ist ein Fehler aufgetreten. Bitte loggen Sie sich erneut ein');
			// alert("Es ist ein Fehler aufgetreten. Bitte loggen Sie sich erneut ein")
			console.log('LOGOUT...');
			setTimeout(() => {
				this.kcLogout();
				this.logoutInProgress = false;
			}, 3000);
		} catch (exception) {
			this.logoutInProgress = false;
		}
		finally {
			this.blockUI.start('Es ist ein Fehler aufgetreten. Bitte loggen Sie sich erneut ein');
		}
	}

	logoutNichtRegistriert(ausfuellerMail: String) {
		if (this.logoutInProgress) {
			return true;
		}
		try {
			this.logoutInProgress = true;
			alert('Ihr Benutzername \'' + ausfuellerMail + '\' ist noch nicht registriert!\n\n' +
					  'Bitte nutzen Sie den Link aus der Registrierungsmail, um Ihr Benutzerkonto freizuschalten!\n\n' +
					  'Erst nach erfolgreicher Registrierung können Sie sich bei JUVE-Recherche anmelden.');
			console.log('LOGOUT...');
			this.kcLogout();
			this.logoutInProgress = false;
		} catch (exception) {
			this.logoutInProgress = false;
		}
		finally {
		}
	}

	logoutArchiviert(ausfuellerMail: String) {
		if (this.logoutInProgress) {
			return true;
		}
		try {
			this.logoutInProgress = true;
			alert('Der Zugang mit dem Benutzernamen \'' + ausfuellerMail + '\' ist gesperrt!\n\n' +
					  'Bitte wenden Sie sich an einen Administrator.');
			console.log('LOGOUT...');
			this.kcLogout();
			this.logoutInProgress = false;
		} catch (exception) {
			this.logoutInProgress = false;
		}
		finally {
		}
	}

	setAktuelleSichtbareInstitutionDto(value: SichtbareInstitutionDto) {
		this._aktuelleInstitution = value;
	}

	private noUserFound(skipRedirect) {
		console.log('Kein Ausfueller gefunden...');
		console.log('skipRedirect: ' + skipRedirect);

		if (skipRedirect !== true) {
			console.log('Navigate To InitPage...');
			this.router.navigate(['/']);
		} else {
			this.logout();
		}

		return null;
	}

	private extractCurrentInstitute(setUserServiceInstituteList) {
		this.loaded = true;
		let tmpInst: Institution;
		if (this._sichtbareInstitutionen.length > 0) {
			this._sichtbareInstitutionen.sort((a, b) => a.institution.anzeigename.toUpperCase().localeCompare(b.institution.anzeigename.toUpperCase()));
			if (setUserServiceInstituteList) {
				this.setSichtbareInstitutionen(this._sichtbareInstitutionen);
			}
			// this.messageService.clear();
			// this.store.pipe(select(selectInstitution)).subscribe(value => tmpInst = new Institution(value));

			if (!this._aktuelleInstitution) {
				this._aktuelleInstitution = this._sichtbareInstitutionen[0];
				this.setAktuelleInstitution(this._aktuelleInstitution.institution);
			} else {
				for (const s of this._sichtbareInstitutionen) {
					if (s.institution.id === this._aktuelleInstitution.institution.id) {
						this._aktuelleInstitution = s;
						// console.log(this._aktuelleInstitution)
					}
				}
			}
		} else {
			this.messageService.clear();
			this.messageService.add({
										severity: 'warn',
										life: 5000,
										summary: 'Es konnten keine Daten geladen werden.'
									});
		}
		this._sichtbareInstitutionen.sort((a, b) => a.institution.anzeigename.toUpperCase().localeCompare(b.institution.anzeigename.toUpperCase()));
		this.setSichtbareInstitutionen(this._sichtbareInstitutionen);
	}
}


