import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbschnittAntwortChangeDetector } from '@form-viewer/abschnitt/abschnitt-antwort-change-detector/AbschnittAntwortChangeDetector';
import { ViewerFrageAutosaveIndicatorComponent } from '@form-viewer/abschnitt/frage-autosave-indicator/ViewerFrageAutosaveIndicatorComponent';
import { ViewerFrageListeComponent } from '@form-viewer/abschnitt/frage-liste/ViewerFrageListeComponent';
import { Ausfueller } from '@form-viewer/model/Ausfueller';
import { FrageFertigMarker } from '@form-viewer/model/FrageFertigMarker';
import { FrageGeprueftMarker } from '@form-viewer/model/FrageGeprueftMarker';
import { AbschnittAntwortService } from '@form-viewer/service/AbschnittAntwortService';
import { AbschnittAusfuellerService } from '@form-viewer/service/AbschnittAusfuellerService';
import { AbschnittReadonlyService } from '@form-viewer/service/AbschnittReadonlyService';
import { AbschnittSperreService } from '@form-viewer/service/AbschnittSperreService';
import { AbschnittStorageService } from '@form-viewer/service/AbschnittStorageService';
import { AusfuellerBerechtigungsService } from '@form-viewer/service/AusfuellerBerechtigungsService';
import { FrageFertigMarkerService } from '@form-viewer/service/FrageFertigMarkerService';
import { FrageGeprueftMarkerService } from '@form-viewer/service/FrageGeprueftMarkerService';
import { PreviewService } from '@form-viewer/service/PreviewService';
import { UserService } from '@form-viewer/service/UserService';
import { Abschnitt } from '@shared/model/Abschnitt';
import { AbschnittAntwortDto } from '@shared/model/AbschnittAntwortDto';
import { Frage } from '@shared/model/Frage';
import { Fragebogen } from '@shared/model/Fragebogen';
import { TickService } from '@shared/service/TickService';
import { UnloadService } from '@util/UnloadService';
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { MessageService } from "primeng/api";
import { Subscription } from 'rxjs/internal/Subscription';
import * as _ from 'underscore';
import { SpinnerComponent } from "../../shared/components/spinner/SpinnerComponent";
import { KartenFrage } from "../../shared/model/frage/KartenFrage";
import { AbschnittAntwortDiffDetector } from "./abschnitt-antwort-change-detector/AbschnittAntwortDiffDetector";
import { AbschnittAutosaveWatcher } from "./abschnitt-autosave-helper/AbschnittAutosaveWatcher";
import { ViewerAbschnittSperreComponent } from "./abschnitt-sperre/ViewerAbschnittSperreComponent";
import { ViewerFragenNavComponent } from "./frage-liste/fragen-nav/ViewerFragenNavComponent";

const debug = require('debug')('ViewerAbschnittComponent');

@Component({
	selector: "app-viewer-abschnitt",
	templateUrl: "./ViewerAbschnittComponent.html",
	styleUrls: ["./ViewerAbschnittComponent.less"],
	standalone: true,
 imports: [ViewerAbschnittSperreComponent, SpinnerComponent, ViewerFrageListeComponent, ViewerFrageAutosaveIndicatorComponent, ViewerFragenNavComponent, NgIf],
})
export class ViewerAbschnittComponent implements OnInit, OnDestroy, OnChanges {
	static readonly SHORT_WARNING_MESSAGE =
		"Ihre ungespeicherten Daten gehen verloren!";

	@BlockUI()
	blockUI: NgBlockUI;

	@Input()
	fragebogen: Fragebogen;

	@Input()
	fragebogen_abgeschlossen: boolean;

	@Input()
	abschnitt: Abschnitt;

	@Output()
	close = new EventEmitter();

	@ViewChild("frageListe")
	frageListe: ViewerFrageListeComponent;

	@ViewChild("autosaveIndicator", { static: true })
	autosaveIndicator: ViewerFrageAutosaveIndicatorComponent;

	subscriptions = new Subscription();

	readonly = true;

	antwort: AbschnittAntwortDto;
	frageFertigMarker: FrageFertigMarker[];
	meineFragenFertigMarker: FrageFertigMarker[];
	frageGeprueftMarker: FrageGeprueftMarker[];

	isImporting = false;

	ausfueller: Ausfueller[];

	aktiveFrage: Frage;
	changeDetector: AbschnittAntwortChangeDetector;
	diffDetector: AbschnittAntwortDiffDetector;

	private removeUnloadCallback: () => void;
	private saveWatcher: AbschnittAutosaveWatcher;

	constructor(
		private abschnittAntwortService: AbschnittAntwortService,
		private abschnittStorageService: AbschnittStorageService,
		private abschnittSperreService: AbschnittSperreService,
		private abschnittReadonlyService: AbschnittReadonlyService,
		private frageFertigMarkerService: FrageFertigMarkerService,
		private abschnittAusfuellerService: AbschnittAusfuellerService,
		private frageGeprueftMarkerService: FrageGeprueftMarkerService,
		private unloadService: UnloadService,
		private previewService: PreviewService,
		private userService: UserService,
		private messageService: MessageService,
		private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService
	) {
		this.changeDetector = new AbschnittAntwortChangeDetector();
		this.diffDetector = new AbschnittAntwortDiffDetector();

		this.saveWatcher = new AbschnittAutosaveWatcher(
			() => this.hasChangesCallback(),
			() => this.changeDetector.compareTo(this.antwort)
		);
	}

	ngOnInit() {
		debug("ngOnInit");
		this.removeUnloadCallback =
			this.unloadService.registerBeforeUnloadCallback(() => {
				if (this.shouldShowSicherheitsfrage()) {
					return ViewerAbschnittComponent.SHORT_WARNING_MESSAGE;
				}
			});

		this.subscriptions.add(
			this.abschnittSperreService.sperrenAktualisiert.subscribe(() => {
				debug("sperren wurden gaendert");
				this.aktualisiereReadonlyStatus();
			})
		);

		this.resetAntwortState();
	}

	ngOnDestroy(): void {
		debug("ngOnDestroy");
		this.saveWatcher.destroy();
		this.removeUnloadCallback();
		this.subscriptions.unsubscribe();
	}

	resetAntwortState(): void {
		debug("resetAntwortState fuer:", this.abschnitt.ueberschrift);
		this.aktiveFrage = this.abschnitt.fragen[0];

		if (this.previewService.isInPreview()) {
			this.antwort = AbschnittAntwortDto.newForAbschnitt(this.abschnitt);
		} else {
			this.abschnittAntwortService
				.getAbschnittAntworten(this.abschnitt)
				.then((antwort) => {
					this.antwort = antwort;

					// Den Frage-Komponenten Zeit geben ihre Antwort-Struktur zu initialisieren
					TickService.onNextTick(() => {
						this.changeDetector.initialize(this.antwort);
						if (this.saveDiff()) {
							this.diffDetector.initialize(this.antwort);
						}
					});
				});
		}

		this.updateMarkerStatus();

		if (this.canSave()) {
			this.saveWatcher.start();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes["abschnitt"]) {
			this.aktualisiereReadonlyStatus();
		}
	}

	private aktualisiereReadonlyStatus(): void {
		this.readonly =
			this.abschnittReadonlyService.sindAbschnittsinhalteInnerhalbDerBearbeitmaskeReadonly(
				this.abschnittStorageService.get(this.fragebogen),
				this.fragebogen_abgeschlossen,
				this.abschnitt
			);

		if (this.canSave()) {
			this.saveWatcher.resume();
		} else {
			this.saveWatcher.pause();
		}

		debug(
			"aktualisiere internen readonly status fuer:",
			this.abschnitt.ueberschrift,
			"readonly:",
			this.readonly
		);
	}

	/**
	 * Der Abschnitt beantwortet werden und der Nutzer hat eine aktuelle Sperre
	 */
	canSave(): boolean {
		return !this.readonly && this.canPotentiallySave();
	}

	/**
	 * User könnte speiechern, wenn er die Sperre für den Abschnitt hat
	 */
	canPotentiallySave(): boolean {
		return (
			!this.fragebogen_abgeschlossen &&
			this.previewService.isNotInPreview()
		);
	}

	showAbschnittSperrenhinweis(): boolean {
		return this.canPotentiallySave();
	}

	isLoaded() {
		return (
			this.antwort &&
			this.frageFertigMarker &&
			this.meineFragenFertigMarker &&
			this.frageGeprueftMarker &&
			this.ausfueller
		);
	}

	saveAndBlock(): Promise<any> {
		if (!this.antwort.antworten.every((a) => a.isValid())) {
			this.messageService.add({
				severity: "error",
				summary: "Es sind ungültige Werte vorhanden.",
			});
			return Promise.reject();
		}

		this.autosaveIndicator.setSaving();
		this.blockUI.start("Daten werden verarbeitet...");

		return this.onCommitSave()
			.then(() => {
				this.autosaveIndicator.setSaved();
				document.getElementById("saveContainer").style.display = "none";
				this.messageService.add({
					severity: "success",
					summary: "Der Abschnitt wurde erfolgreich gespeichert.",
				});
			})
			.catch(() => {
				this.autosaveIndicator.setError();
				this.messageService.add({
					severity: "error",
					summary:
						"Beim Speichern ist ein Fehler aufgetreten. Sollte der Fehler wiederholt auftreten, wenden Sie sich bitte an einen Administrator.",
				});
			})
			.finally(() => {
				this.blockUI.stop();
			});
	}

	hasChangesCallback() {
		document.getElementById("saveContainer").style.display = "block";
		this.saveWatcher.pause();
	}

	onCommitSave(): Promise<any> {
		if (!this.canSave()) {
			return Promise.resolve();
		}

		const speicherbareAntworten = this.filterSpeicherbareAntworten();
		if (speicherbareAntworten.antworten.length === 0) {
			return Promise.resolve();
		}

		console.log("onCommitSave – speichere Antworten");

		this.saveWatcher.pause();
		// if (this.saveDiff()) {
		// 	debug('speichere Diff von Mandaten')
		// 	let dto = this.diffDetector.getDiff(this.antwort);
		// 	this.antwort
		// 	if (dto) {
		// 		return this.abschnittAntwortService.saveAbschnittAntwortenDiff(dto)
		// 				   .then(() => {
		// 					   this.changeDetector.initialize(this.antwort);
		// 					   this.diffDetector.initialize(this.antwort);
		// 					   this.saveWatcher.resume();
		// 				   })
		// 				   .catch(() => {
		// 					   this.saveWatcher.resume();
		// 				   });
		// 	}
		// 	return Promise.resolve()
		// } else {
		return this.abschnittAntwortService
			.saveAbschnittAntworten(speicherbareAntworten)
			.then((antwort) => {
				// unfertigeAntworten referenziert die Antwort-Objekte aus this.antwort und kann daher direkt zum Zurückschreiben der IDs
				// benutzt werden.
				speicherbareAntworten.applyIds(antwort);

				this.changeDetector.initialize(this.antwort);
				this.saveWatcher.resume();
			})
			.catch(() => {
				this.saveWatcher.resume();
				return Promise.reject();
			});
		// }
	}

	private saveDiff() {
		return (
			this.abschnitt.ueberschrift == "Mandate" &&
			this.abschnitt.fragen.every(
				(frage) => frage instanceof KartenFrage && frage.id
			)
		);
	}

	filterSpeicherbareAntworten() {
		const fertigeFragenIds = this.meineFragenFertigMarker.map(
			(marker) => marker.frage_id
		);
		const gepruefteFragenIds = this.frageGeprueftMarker.map(
			(marker) => marker.frage_id
		);

		// Falls der geneigte Leser sich fragt, wieso hier nicht der Copy-Konstruktor des AbschnittAntwortDtos genutzt wird:
		// -----------------------------------------------------------------------------------------------------------------
		// Der copy-Konstruktor von AbschnittAntwortDto ist zu vermeiden, da er Kopien der übergebenen Antworten erstellt
		// und damit die Referenz zu den Antworten der Komponente bricht. Dadurch aktualisiert ein späterer Aufruf von applyIds nur noch
		// die Ids der Kopien, nicht aber der richtigen Antworten der Komponente. Aus diesem Grund werden hier nur Referenzen auf die
		// originalen Antworten erstellt.
		const abschnittAntwortDto = new AbschnittAntwortDto();
		abschnittAntwortDto.abschnitt_id = this.antwort.abschnitt_id;
		abschnittAntwortDto.antworten = this.antwort.antworten.filter(
			(antwort) =>
				!_.contains(fertigeFragenIds, antwort.frage_id) &&
				!_.contains(gepruefteFragenIds, antwort.frage_id)
		);

		return abschnittAntwortDto;
	}

	hasChanges() {
		return this.changeDetector.countChanges(this.antwort) > 0;
	}

	private saveIfHasChanges(): Promise<void> {
		if (this.hasChanges()) {
			return this.saveAndBlock();
		} else {
			return Promise.resolve();
		}
	}

	shouldShowSicherheitsfrage(): boolean {
		return this.canSave() && this.hasChanges();
	}

	onCommitSaveThenClose() {
		try {
			if (this.previewService.isInPreview()) {
				this.saveWatcher.stop();
				this.close.emit();
			} else {
				this.saveIfHasChanges()
					.then(() => {
						this.saveWatcher.stop();
						this.close.emit();
					})
					.catch((error) => {
						console.error("Error during save:", error);
						this.messageService.add({
													severity: "error",
													summary: "Fehler beim Speichern",
													detail: "Es ist ein Fehler beim Speichern aufgetreten. Bitte versuchen Sie es erneut.",
												});
					});
			}
		} catch (error) {
			console.error("Unexpected error:", error);
			this.messageService.add({
										severity: "error",
										summary: "Unerwarteter Fehler",
										detail: "Ein unerwarteter Fehler ist aufgetreten. Bitte wenden Sie sich an den Support.",
									});
		}
	}

	frageEntered(frage: Frage) {
		this.aktiveFrage = frage;
	}

	onImported() {
		this.resetAntwortState();
	}

	onAlsFertigMarkiert(frage: Frage) {
		this.saveIfHasChanges().then(() => {
			this.frageFertigMarkerService.alsFertigMarkieren(frage).then(() => {
				// this.updateMarkerStatus();//TODO: umbauen damit reload nicht mehr benötigt wird
				this.close.emit();
			});
		});
	}

	onAlsUnfertigMarkiert(frage: Frage) {
		this.frageFertigMarkerService.alsUnfertigMarkieren(frage).then(() => {
			// this.updateMarkerStatus();//TODO: umbauen damit reload nicht mehr benötigt wird
			this.close.emit();
		});
	}

	onAlsGeprueftMarkiert(frage: Frage) {
		this.saveIfHasChanges().then(() => {
			this.frageGeprueftMarkerService
				.alsGeprueftMarkieren(frage)
				.then(() => {
					// this.updateMarkerStatus();//TODO: umbauen damit reload nicht mehr benötigt wird
					this.close.emit();
				});
		});
	}

	onAlsUngeprueftMarkiert(frage: Frage) {
		this.frageGeprueftMarkerService
			.alsUngeprueftMarkieren(frage)
			.then(() => {
				// this.updateMarkerStatus();//TODO: umbauen damit reload nicht mehr benötigt wird
				this.close.emit();
			});
	}

	onFileuploadWorking(working: boolean) {
		this.isImporting = working;
		this.readonly = working;
	}

	private updateMarkerStatus() {
		if (this.previewService.isInPreview()) {
			debug(
				"updateMarkerStatus - isInPreview=true - initializing data empty"
			);

			this.frageGeprueftMarker = [];
			this.frageFertigMarker = [];
			this.ausfueller = [];
			this.meineFragenFertigMarker = [];
			return;
		}

		this.frageGeprueftMarkerService
			.getGeprueftMarkerFuerAbschnitt(this.abschnitt)
			.then((marker) => {
				this.frageGeprueftMarker = marker;
			});

		if (this.ausfuellerBerechtigungsService.darfFrageFertigMarkerSehen()) {
			debug(
				"updateMarkerStatus - darfFrageFertigMarkerSehen=true -> fetching frageFertigMarker and ausfueller"
			);

			this.frageFertigMarkerService
				.getFertigMarkerFuerAbschnitt(this.abschnitt)
				.then((frageFertigMarker) => {
					this.frageFertigMarker = frageFertigMarker;
				});

			this.abschnittAusfuellerService
				.getAusfuellerFuerAbschnitt(this.abschnitt)
				.then((ausfueller) => {
					this.ausfueller = ausfueller;
				});
		} else {
			debug(
				"updateFertigMarkiertStatus - darfFrageFertigMarkerSehen=false -> initializing frageFertigMarker and ausfueller empty"
			);
			this.frageFertigMarker = [];
			this.ausfueller = [];
		}

		if (
			this.ausfuellerBerechtigungsService.darfFrageAlsFertigMarkierenFuerAbschnitt(
				this.abschnitt
			)
		) {
			debug(
				"updateMarkerStatus - darfFrageAlsFertigMarkieren=true -> fetching meineFragenFertigMarker"
			);
			this.frageFertigMarkerService
				.getMeineFertigMarkerFuerAbschnitt(this.abschnitt)
				.then((meineFrageFertigMarker) => {
					this.meineFragenFertigMarker = meineFrageFertigMarker;
				});
		} else {
			debug(
				"updateFertigMarkiertStatus - darfFrageAlsFertigMarkieren=false -> initializing meineFragenFertigMarker empty"
			);
			this.meineFragenFertigMarker = [];
		}
	}
}
