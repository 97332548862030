/* eslint-disable max-len */
import {PortalModule} from '@angular/cdk/portal';
import {Location, LocationStrategy, PathLocationStrategy, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {ApplicationRef, LOCALE_ID, NgModule, Type} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {BackButtonDisableModule} from 'angular-disable-browser-back-button';
import {JuveAnsprechpartnerboxComponent} from 'app/component/ansprechpartnerbox/JuveAnsprechpartnerboxComponent';
import {AbschnittComponent} from 'app/form-editor/abschnitt-liste/abschnitt/AbschnittComponent';
import {AbschnittEditComponent} from 'app/form-editor/abschnitt-liste/abschnitt/edit/AbschnittEditComponent';
import {EntscheidungsFrageEditComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/entscheidung/EntscheidungsFrageEditComponent';
import {FrageEditComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/FrageEditComponent';
import {KartenFrageEditComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/karten/KartenFrageEditComponent';
import {
	KartenFrageEditSpalteComponent
} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/karten/KartenFrageEditSpalteComponent/KartenFrageEditSpalteComponent';
import {
	KartenFrageEditTitelComponent
} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/karten/KartenFrageEditTitelComponent/KartenFrageEditTitelComponent';
import {
	MehrfachauswahlFrageEditComponent
} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/mehrfachauswahl/MehrfachauswahlFrageEditComponent';
import {
	TabelleErweiterbarFrageEditComponent
} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/tabelle/erweiterbar/TabelleErweiterbarFrageEditComponent';
import {TabelleFixFrageEditComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/tabelle/fix/TabelleFixFrageEditComponent';

import {TabelleFrageEditComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/tabelle/TabelleFrageEditComponent';
import {TabelleFrageTitleEditComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/tabelle/titel/TabelleFrageTitleEditComponent';
import {TextfeldFrageEditComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/textfeld/TextfeldFrageEditComponent';
import {SortableOptionsComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/widgets/sortable-options/SortableOptionsComponent';
import {FrageComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/FrageComponent';

import {FrageViewComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/view/FrageViewComponent';
import {FrageListeComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/FrageListeComponent';
import {AbschnittViewComponent} from 'app/form-editor/abschnitt-liste/abschnitt/view/AbschnittViewComponent';
import {AbschnittListeComponent} from 'app/form-editor/abschnitt-liste/AbschnittListeComponent';
import {AbschnittSortierenComponent} from 'app/form-editor/abschnitt-sortieren-dialog/abschnitt-sortieren-component/AbschnittSortierenComponent';
import {AbschnittSortierenDialogComponent} from 'app/form-editor/abschnitt-sortieren-dialog/AbschnittSortierenDialogComponent';
import {InstitutionsUebersichtComponent} from 'app/form-editor/antworten/institutions-auswahl/institution-uebersicht/InstitutionsUebersichtComponent';
import {InstitutionsAuswahlComponent} from 'app/form-editor/antworten/institutions-auswahl/InstitutionsAuswahlComponent';
import {FormEditorHeaderComponent} from 'app/form-editor/editor-header/FormEditorHeaderComponent';

import {FormEditorComponent} from 'app/form-editor/FormEditorComponent';
import {FragebogenMetadataDialogComponent} from 'app/form-editor/fragebogen-metadata-dialog/FragebogenMetadataDialogComponent';
import {FragebogenMetadataFormComponent} from 'app/form-editor/fragebogen-metadata-form/FragebogenMetadataFormComponent';
import {EditorFragebogenUebersichtComponent} from 'app/form-editor/fragebogen-uebersicht/EditorFragebogenUebersichtComponent';
import {
	EditorFragebogenListeDuplicateModalComponent
} from 'app/form-editor/fragebogen-uebersicht/fragebogen-liste-duplicate-modal/EditorFragebogenListeDuplicateModalComponent';
import {
	EditorFragebogenListeVeroeffentlichenModalComponent
} from 'app/form-editor/fragebogen-uebersicht/fragebogen-liste-veroeffentlichen-modal/EditorFragebogenListeVeroeffentlichenModalComponent';
import {EditorFragebogenListComponent} from 'app/form-editor/fragebogen-uebersicht/fragebogen-liste/EditorFragebogenListComponent';
import {AbschnittEditorService} from 'app/form-editor/service/AbschnittEditorService';
import {FragebogenDuplizierenService} from 'app/form-editor/service/FragebogenDuplizierenService';
import {FragebogenLoeschenService} from 'app/form-editor/service/FragebogenLoeschenService';
import {FragebogenVeroeffentlichenService} from 'app/form-editor/service/FragebogenVeroeffentlichenService';
import {FrageEditorService} from 'app/form-editor/service/FrageEditorService';
import {FragenSortService} from 'app/form-editor/service/FragenSortService';
import {InfoTextEditComponent} from 'app/form-editor/shared/info-text/InfoTextEditComponent';
import {ViewerAbschnittListeComponent} from 'app/form-viewer/abschnitt-liste/ViewerAbschnittListeComponent';
import {ViewerAbschnittNavbarComponent} from 'app/form-viewer/abschnitt-navbar/ViewerAbschnittNavbarComponent';
import {ViewerAbschnittSperreComponent} from 'app/form-viewer/abschnitt/abschnitt-sperre/ViewerAbschnittSperreComponent';
import {ViewerFrageAutosaveIndicatorComponent} from 'app/form-viewer/abschnitt/frage-autosave-indicator/ViewerFrageAutosaveIndicatorComponent';
import {
	ViewerAntwortAlsFertigMarkierenComponent
} from 'app/form-viewer/abschnitt/frage-liste/antwort-als-fertig-markieren/ViewerAntwortAlsFertigMarkierenComponent';
import {
	ViewerAntwortAlsFertigMarkiertListeComponent
} from 'app/form-viewer/abschnitt/frage-liste/antwort-als-fertig-markiert-liste/ViewerAntwortAlsFertigMarkiertListeComponent';
import {
	ViewerAntwortAlsGeprueftMarkierenComponent
} from 'app/form-viewer/abschnitt/frage-liste/antwort-als-geprueft-markieren/ViewerAntwortAlsGeprueftMarkierenComponent';
import {EntscheidungsFrageViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/entscheidung/EntscheidungsFrageViewComponent';
import {ViewerExportTriggerComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/export-trigger/ViewerExportTriggerComponent';
import {ViewerImportTriggerComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/import-trigger/ViewerImportTriggerComponent';
import {InputTypeAuswahlViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-type-auswahl-view/InputTypeAuswahlViewComponent';
import {InputTypeCheckboxViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-type-checkbox-view/InputTypeCheckboxViewComponent';
import {InputTypeGanzzahlViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-type-ganzzahl-view/InputTypeGanzzahlViewComponent';
import {
	InputTypeKommazahlViewComponent
} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-type-kommazahl-view/InputTypeKommazahlViewComponent';
import {
	InputTypeMehrfachauswahlViewComponent
} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-type-mehrfachauswahl-view/InputTypeMehrfachauswahlViewComponent';
import {InputTypeTextViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-type-text-view/InputTypeTextViewComponent';
import {InputTypeTextareaViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-type-textarea-view/InputTypeTextareaViewComponent';
import {InputViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-view/InputViewComponent';
import {KartenAddButtonComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/karte/add-button/KartenAddButtonComponent';
import {KartenViewSpalteComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/karte/karten-view/karten-view-spalte/KartenViewSpalteComponent';
import {
	KartenViewTitleFieldComponent
} from 'app/form-viewer/abschnitt/frage-liste/frage/view/karte/karten-view/karten-view-title-field/KartenViewTitleFieldComponent';
import {KartenViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/karte/karten-view/KartenViewComponent';
import {KartenFrageViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/karte/KartenFrageViewComponent';
import {MehrfachauswahlFrageViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/mehrfachauswahl/MehrfachauswahlFrageViewComponent';
import {
	TabelleErweiterbarViewComponent
} from 'app/form-viewer/abschnitt/frage-liste/frage/view/tabelle/tabelle-erweiterbar-view/TabelleErweiterbarViewComponent';
import {TabelleFixViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/tabelle/tabelle-fix-view/TabelleFixViewComponent';
import {TabelleFrageViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/tabelle/TabelleFrageViewComponent';
import {TextfeldFrageViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/textfeld/TextfeldFrageViewComponent';
import {ViewerFrageViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/ViewerFrageViewComponent';
import {ViewerFrageComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/ViewerFrageComponent';
import {ViewerFragenNavEintragComponent} from 'app/form-viewer/abschnitt/frage-liste/fragen-nav/fragen-nav-eintrag/ViewerFragenNavEintragComponent';
import {ViewerFragenNavComponent} from 'app/form-viewer/abschnitt/frage-liste/fragen-nav/ViewerFragenNavComponent';
import {ViewerFrageListeComponent} from 'app/form-viewer/abschnitt/frage-liste/ViewerFrageListeComponent';
import {ViewerAbschnittComponent} from 'app/form-viewer/abschnitt/ViewerAbschnittComponent';
import {AusfuellerVerwaltungDialogButtonComponent} from 'app/form-viewer/ausfueller-verwaltung/button/AusfuellerVerwaltungDialogButtonComponent';
import {AusfuellerVerwaltungDialogComponent} from 'app/form-viewer/ausfueller-verwaltung/dialog/AusfuellerVerwaltungDialogComponent';
import {
	AbschnittsausfuellerVerwaltungFormComponent
} from 'app/form-viewer/ausfueller-verwaltung/form/abschnittsausfueller/AbschnittsausfuellerVerwaltungFormComponent';
import {AusfuellerAnlegenService} from 'app/form-viewer/ausfueller-verwaltung/form/AusfuellerAnlegenService';
import {AusfuellerBenutzerdatenApiService} from 'app/form-viewer/ausfueller-verwaltung/form/AusfuellerBenutzerdatenApiService';
import {AusfuellerBenutzerdatenService} from 'app/form-viewer/ausfueller-verwaltung/form/AusfuellerBenutzerdatenService';
import {AusfuellerVerwaltungFormComponent} from 'app/form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungFormComponent';
import {CoAdminVerwaltungFormComponent} from 'app/form-viewer/ausfueller-verwaltung/form/co-admin/CoAdminVerwaltungFormComponent';
import {
	FragebogenausfuellerVerwaltungFormComponent
} from 'app/form-viewer/ausfueller-verwaltung/form/fragebogenausfueller/FragebogenausfuellerVerwaltungFormComponent';
import {FormViewerComponent} from 'app/form-viewer/FormViewerComponent';
import {FragebogenAbschliessenComponent} from 'app/form-viewer/fragebogen-abschliessen/FragebogenAbschliessenComponent';
import {FragebogenAufschliessenComponent} from 'app/form-viewer/fragebogen-aufschliessen/FragebogenAufschliessenComponent';
import {FragebogenDruckenComponent} from 'app/form-viewer/fragebogen-drucken/FragebogenDruckenComponent';
import {FragebogenListeViewComponent} from 'app/form-viewer/fragebogen-liste/FragebogenListeViewComponent';
import {ViewerFragebogenNavbarComponent} from 'app/form-viewer/fragebogen-navbar/ViewerFragebogenNavbarComponent';
import {ViewerHinweisComponent} from 'app/form-viewer/hinweis/ViewerHinweisComponent';
import {FrageboegenNichtWiedereroeffenbarComponent} from 'app/form-viewer/modal/frageboegen-nicht-wiedereroeffenbar/FrageboegenNichtWiedereroeffenbarComponent';
import {
	RecherchekomplexBeantwortenNichtMehrMoeglichModalComponent
} from 'app/form-viewer/modal/recherchekomplex-beantworten-nicht-mehr-moeglich-modal/RecherchekomplexBeantwortenNichtMehrMoeglichModalComponent';
import {AbschnittAntwortService} from 'app/form-viewer/service/AbschnittAntwortService';
import {AbschnittAusfuellerService} from 'app/form-viewer/service/AbschnittAusfuellerService';
import {AbschnittInfoService} from 'app/form-viewer/service/AbschnittInfoService';
import {AbschnittReadonlyService} from 'app/form-viewer/service/AbschnittReadonlyService';
import {AbschnittSperreService} from 'app/form-viewer/service/AbschnittSperreService';
import {AbschnittStorageService} from 'app/form-viewer/service/AbschnittStorageService';
import {AusfuellerAbgabezeitpunktService} from 'app/form-viewer/service/AusfuellerAbgabezeitpunktService';
import {AusfuellerBerechtigungsService} from 'app/form-viewer/service/AusfuellerBerechtigungsService';
import {FragebogenStatusService} from 'app/form-viewer/service/FragebogenStatusService';
import {FrageFertigMarkerService} from 'app/form-viewer/service/FrageFertigMarkerService';
import {FrageGeprueftMarkerService} from 'app/form-viewer/service/FrageGeprueftMarkerService';
import {NotificationSeenService} from 'app/form-viewer/service/NotificationSeenService';
import {PreviewService} from 'app/form-viewer/service/PreviewService';
import {UserService} from 'app/form-viewer/service/UserService';
import {ViewerAbschnittService} from 'app/form-viewer/service/ViewerAbschnittService';
import {InfoTextViewComponent} from 'app/form-viewer/shared/info-text/InfoTextViewComponent';
import {ScrollToFocusedElementDirective} from 'app/form-viewer/util/ScrollToFocusedElementDirective';
import {XsrfFormFieldComponent} from 'app/form-viewer/util/XsrfFormFieldComponent';
import {ViewerPortalRecherchekomplexComponent} from 'app/form-viewer/viewer-portal-recherchekomplex/ViewerPortalRecherchekomplexComponent';

import {PasswordEingabeComponent} from 'app/passwort-eingabe/PasswordEingabeComponent';
import {PasswordStrengthComponent} from 'app/passwort-eingabe/PasswordStrengthComponent';
import {PasswordZuruecksetzenFormularComponent} from 'app/passwort-eingabe/PasswordZuruecksetzenFormularComponent';
import {RegistrierungsFormularComponent} from 'app/passwort-eingabe/RegistrierungsFormularComponent';
import {RegistrierungsWrapperComponent} from 'app/passwort-eingabe/RegistrierungsWrapperComponent';
import {PortalRecherchekomplexEintragComponent} from 'app/portal-selector/portal-recherchekomplex-eintrag/PortalRecherchekomplexEintragComponent';
import {PortalInstitutionSelectorComponent} from 'app/portal-selector/PortalInstitutionSelectorComponent';
import {PortalRecherchekomplexSelectorComponent} from 'app/portal-selector/PortalRecherchekomplexSelectorComponent';
import {PortalSelectorComponent} from 'app/portal-selector/PortalSelectorComponent';
import {AlertComponent} from 'app/shared/alert/component/AlertComponent';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {InputTypeAuswahlEditComponent} from 'app/shared/components/input-type/auswahl/InputTypeAuswahlEditComponent';
import {InputTypeGanzzahlEditComponent} from 'app/shared/components/input-type/ganzzahl/InputTypeGanzzahlEditComponent';
import {InputTypeEditComponent} from 'app/shared/components/input-type/InputTypeEditComponent';
import {InputTypeKommazahlEditComponent} from 'app/shared/components/input-type/kommazahl/InputTypeKommazahlEditComponent';
import {InputTypeMehrfachauswahlEditComponent} from 'app/shared/components/input-type/mehrfachauswahl/InputTypeMehrfachauswahlEditComponent';
import {InputTypeTextEditComponent} from 'app/shared/components/input-type/text/InputTypeTextEditComponent';
import {InputTypeTextareaEditComponent} from 'app/shared/components/input-type/textarea/InputTypeTextareaEditComponent';
import {SelectComponent} from 'app/shared/components/select/basic/SelectComponent';
import {SelectMitSonstigesOptionComponent} from 'app/shared/components/select/mit-sonstiges-option/SelectMitSonstigesOptionComponent';
import {MultiSelectComponent} from 'app/shared/components/select/multi/MultiSelectComponent';
import {StyledSelectComponent} from 'app/shared/components/select/styled/StyledSelectComponent';
import {SpinnerComponent} from 'app/shared/components/spinner/SpinnerComponent';
import {AbschnittUrlHashService} from 'app/shared/service/AbschnittUrlHashService';
import {AktuelleBerechtigungService} from 'app/shared/service/AktuelleBerechtigungService';
import {EditLockService} from 'app/shared/service/EditLockService';
import {FragebogenService} from 'app/shared/service/FragebogenService';
import {InstitutionAntwortService} from 'app/shared/service/InstitutionAntwortService';
import {NavigationService} from 'app/shared/service/NavigationService';
import {QueryParamsService} from 'app/shared/service/QueryParamsService';

import {RecherchekomplexService} from 'app/shared/service/RecherchekomplexService';
import {DummyFormViewerAbschnittSperrenHelper} from 'app/sperren-helper/DummyFormViewerAbschnittSperrenHelper';
import {FormViewerAbschnittSperrenHelper} from 'app/sperren-helper/FormViewerAbschnittSperrenHelper';
import {CapitalizeFirst} from 'app/util/CapitalizeFirst';
import {DekliniereAnredePipe} from 'app/util/DekliniereAnredePipe';
import {LastIdPipe} from 'app/util/LastIdPipe';
import {MapIteratorPipe} from 'app/util/MapIteratorPipe';
import {NewIdPipe} from 'app/util/NewIdPipe';
import {NewlineToParagraphsPipe} from 'app/util/NewlineToParagraphsPipe';
import {PreventDefaultDirective} from 'app/util/PreventDefaultDirective';
import {PreventSubmitOnEnterDirective} from 'app/util/PreventSubmitOnEnterDirective';
import {SimplePluralPipe} from 'app/util/SimplePluralPipe';
import {UnloadService} from 'app/util/UnloadService';
import {BlockUIModule} from 'ng-block-ui';
import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ClipboardModule} from 'ngx-clipboard';

import {CookieService} from "ngx-cookie-service";
import {AccordionModule} from "primeng/accordion";
import {MessageService} from "primeng/api";
import {AvatarModule} from "primeng/avatar";
import {BadgeModule} from "primeng/badge";
import {ButtonModule} from "primeng/button";
import {CalendarModule} from "primeng/calendar";
import {CardModule} from "primeng/card";
import {CheckboxModule} from "primeng/checkbox";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from "primeng/fieldset";
import {InputNumberModule} from 'primeng/inputnumber';
import {InputSwitchModule} from "primeng/inputswitch";
import {InputTextModule} from "primeng/inputtext";
import {KeyFilterModule} from 'primeng/keyfilter';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {MultiSelectModule} from "primeng/multiselect";
import {OrderListModule} from 'primeng/orderlist';
import {PaginatorModule} from "primeng/paginator";
import {PasswordModule} from "primeng/password";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {RippleModule} from "primeng/ripple";
import {TableModule} from "primeng/table";
import {TabViewModule} from 'primeng/tabview';
import {TagModule} from "primeng/tag";
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from "primeng/togglebutton";
import {AuthModule} from "../auth/auth.module";
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BenutzerverwaltungAbschnittComponent} from './benutzerverwaltung/component/alt-benutzerverwaltung-abschnitt/BenutzerverwaltungAbschnittComponent';
import {
	BenutzerverwaltungDialogLoescheAusfuellerFormComponent
} from './benutzerverwaltung/component/alt-benutzerverwaltung-dialog-loesche-ausfueller-form/BenutzerverwaltungDialogLoescheAusfuellerFormComponent';
import {
	BenutzerverwaltungDialogLoescheAusfuellerComponent
} from './benutzerverwaltung/component/alt-benutzerverwaltung-dialog-loesche-ausfueller/BenutzerverwaltungDialogLoescheAusfuellerComponent';
import {BenutzerverwaltungFragebogenComponent} from './benutzerverwaltung/component/alt-benutzerverwaltung-fragebogen/BenutzerverwaltungFragebogenComponent';
import {
	BenutzerverwaltungKarteNichtRegistrierteAusfuellerComponent
} from './benutzerverwaltung/component/alt-benutzerverwaltung-karte-nicht-registrierte-ausfueller/BenutzerverwaltungKarteNichtRegistrierteAusfuellerComponent';
import {
	BenutzerverwaltungKarteRegistrierteAusfuellerComponent
} from './benutzerverwaltung/component/alt-benutzerverwaltung-karte-registrierte-ausfueller/BenutzerverwaltungKarteRegistrierteAusfuellerComponent';
import {
	BenutzerverwaltungRecherchekomplexComponent
} from './benutzerverwaltung/component/alt-benutzerverwaltung-recherchekomplex/BenutzerverwaltungRecherchekomplexComponent';
import {BenutzerverwaltungComponent} from './benutzerverwaltung/component/BenutzerverwaltungComponent';
import {InaktiveBenutzerComponent} from './benutzerverwaltung/component/inaktive-benutzer/inaktive-benutzer.component';
import {
	OffeneEinladungBearbeitenComponent
} from './benutzerverwaltung/component/offene-einladungen/offene-einladung-bearbeiten-old/offene-einladung-bearbeiten.component';
import {OffeneEinladungenComponent} from './benutzerverwaltung/component/offene-einladungen/offene-einladungen.component';
import {
	RecherchekomplexBenutzerComponent
} from './benutzerverwaltung/component/recherchekomplexe/recherchekomplex-benutzer/recherchekomplex-benutzer.component';
import {RecherchekomplexeComponent} from "./benutzerverwaltung/component/recherchekomplexe/recherchekomplexe.component";
import {BenutzerBearbeitenComponent} from "./benutzerverwaltung/component/registrierte-benutzer/benutzer-bearbeiten/benutzer-bearbeiten.component";
import {RechteVerwaltenComponent} from "./benutzerverwaltung/component/registrierte-benutzer/benutzer-bearbeiten/rechte-verwalten/rechte-verwalten.component";
import {RegistrierteBenutzerComponent} from './benutzerverwaltung/component/registrierte-benutzer/registrierte-benutzer.component';
import {RecherchekomplexUebersichtService} from "./benutzerverwaltung/services/recherchekomplex-uebersicht.service";
import {CustomHttpInterceptor} from "./errors/http.custom.interceptor";
import {AntwortComponent} from './form-editor/antworten/antwort/AntwortComponent';
import {EditorAntwortenComponent} from './form-editor/antworten/EditorAntwortenComponent';
import {AntwortAnsichtService} from './form-editor/service/AntwortAnsichtService';
import {FormEditorVorschauComponent} from './form-editor/vorschau/FormEditorVorschauComponent';
import {InputTypeEmailViewComponent} from "./form-viewer/abschnitt/frage-liste/frage/view/input/input-type-email-view/InputTypeEmailViewComponent";
import {InputTypeTelefonViewComponent} from "./form-viewer/abschnitt/frage-liste/frage/view/input/input-type-telefon-view/InputTypeTelefonViewComponent";
import {FormViewerWrapperComponent} from './form-viewer/form-viewer-wrapper/FormViewerWrapperComponent';
import {FindAusfuellerService} from "./form-viewer/service/FindAusfuellerService";
import {ArchivComponent} from './pages/archiv/ArchivComponent';
import {FaqComponent} from './pages/faq/FaqComponent';
import {InitPageComponent} from "./pages/init/InitPageComponent";
import {PortalAllgemeinComponent} from './pages/portal-allgemein/PortalAllgemeinComponent';
import {PortalComponent} from './pages/portal/PortalComponent';
import {TeaserComponent} from './pages/portal/TeaserComponent';
import {ContentService} from './pages/services/ContentService';
import {SicherheitComponent} from './pages/sicherheit/SicherheitComponent';
import {TeamComponent} from './pages/team/TeamComponent';
import {RegistrierungService} from "./passwort-eingabe/service/RegistrierungService";
import {UnbekannterCodeComponent} from "./passwort-eingabe/UnbekannterCodeComponent";
import {metaReducers, reducers} from './reducers';

import {ForbiddenComponent} from './security/403/ForbiddenComponent';
import {AuthGuardEditor} from './security/AuthGuardEditor';
import {AuthGuardViewer} from './security/AuthGuardViewer';
import {AuthGuardViewerRK} from './security/AuthGuardViewerRK';
import {PasswortZuruecksetzenComponent} from "./security/passwort-zuruecksetzen/PasswortZuruecksetzenComponent";
import {PasswortZuruecksetzenService} from "./security/passwort-zuruecksetzen/PasswortZuruecksetzenService";
import {XhrInterceptor} from './security/xhr.interceptor';
import {ContactComponent} from './shared/components/contact/ContactComponent';
import {FooterComponent} from './shared/components/footer/FooterComponent';
import {HeaderComponent} from './shared/components/header/HeaderComponent';
import {InputTypeEmailEditComponent} from "./shared/components/input-type/email/InputTypeEmailEditComponent";
import {InputTypeTelefonEditComponent} from "./shared/components/input-type/telefon/InputTypeTelefonEditComponent";
import {MessageComponent} from './shared/components/message/MessageComponent';
import {NewTabComponent} from './shared/components/new-tab.component';
import {LoggingService} from "./shared/service/LoggingService";
import {institutionFeatureKey, institutionReducer} from './store/reducer/institution.reducer';
import {recherchekomplexFeatureKey, recherchekomplexReducer} from './store/reducer/recherchekomplex.reducer';
import {EnabledPipe} from "./util/enabledPipe";
import {HtmlSanitizerPipe} from "./util/HtmlSanitizerPipe";
import {VarDirective} from './var.directive';

export function jurecEasing(t: number, b: number, c: number, d: number): number {
	if ((t /= d / 2) < 1) {
		return c / 2 * t * t + b;
	}
	return -c / 2 * ((--t) * (t - 2) - 1) + b;
}


@NgModule({
	declarations: [
		AppComponent,
		FormEditorComponent,
		FormEditorHeaderComponent,
		FragebogenMetadataDialogComponent,
		FragebogenMetadataFormComponent,
		FormViewerComponent,
		FrageboegenNichtWiedereroeffenbarComponent,
		RecherchekomplexBeantwortenNichtMehrMoeglichModalComponent,
		FragebogenDruckenComponent,

		EditorFragebogenUebersichtComponent,
		EditorFragebogenListComponent,
		EditorFragebogenListeDuplicateModalComponent,
		EditorFragebogenListeVeroeffentlichenModalComponent,

		AbschnittSortierenDialogComponent,
		AbschnittSortierenComponent,

		FragebogenAbschliessenComponent,
		FragebogenAufschliessenComponent,
		AbschnittListeComponent,
		FragebogenListeViewComponent,
		AbschnittComponent,
		AlertComponent,
		AbschnittViewComponent,
		AbschnittEditComponent,
		FrageListeComponent,
		FrageComponent,
		FrageViewComponent,
		FrageEditComponent,
		TextfeldFrageEditComponent,
		MehrfachauswahlFrageEditComponent,
		EntscheidungsFrageEditComponent,
		TabelleFrageEditComponent,
		TabelleFixFrageEditComponent,
		TabelleErweiterbarFrageEditComponent,
		TabelleFrageTitleEditComponent,
		ViewerExportTriggerComponent,
		ViewerImportTriggerComponent,

		SelectComponent,
		StyledSelectComponent,
		SelectMitSonstigesOptionComponent,
		MultiSelectComponent,

		NewIdPipe,
		LastIdPipe,
		MapIteratorPipe,
		DekliniereAnredePipe,
		CapitalizeFirst,
		NewlineToParagraphsPipe,
		SimplePluralPipe,
		HtmlSanitizerPipe,
		EnabledPipe,

		PreventDefaultDirective,
		PreventSubmitOnEnterDirective,
		ScrollToFocusedElementDirective,

		ViewerAbschnittListeComponent,
		ViewerAbschnittComponent,

		ViewerFrageComponent,
		ViewerFrageListeComponent,
		ViewerFrageViewComponent,
		ViewerFrageAutosaveIndicatorComponent,

		ViewerAbschnittNavbarComponent,

		EntscheidungsFrageViewComponent,
		TextfeldFrageViewComponent,
		MehrfachauswahlFrageViewComponent,
		TabelleFrageViewComponent,

		TabelleFixViewComponent,
		TabelleErweiterbarViewComponent,

		KartenFrageEditComponent,
		KartenFrageEditSpalteComponent,
		KartenFrageEditTitelComponent,
		KartenFrageViewComponent,

		KartenViewComponent,
		KartenViewSpalteComponent,
		KartenAddButtonComponent,
		KartenViewTitleFieldComponent,

		InputTypeEditComponent,
		InputTypeAuswahlEditComponent,

		InputViewComponent,

		InputTypeTextViewComponent,
		InputTypeTextEditComponent,

		InputTypeTextareaViewComponent,
		InputTypeTextareaEditComponent,

		InputTypeCheckboxViewComponent,
		InputTypeAuswahlViewComponent,

		InputTypeMehrfachauswahlViewComponent,
		InputTypeMehrfachauswahlEditComponent,

		InputTypeGanzzahlViewComponent,
		InputTypeGanzzahlEditComponent,

		InputTypeKommazahlViewComponent,
		InputTypeKommazahlEditComponent,

		InputTypeEmailViewComponent,
		InputTypeEmailEditComponent,

		InputTypeTelefonViewComponent,
		InputTypeTelefonEditComponent,

		InfoTextEditComponent,
		InfoTextViewComponent,
		SpinnerComponent,

		ViewerAbschnittSperreComponent,
		ViewerHinweisComponent,

		InstitutionsAuswahlComponent,
		InstitutionsUebersichtComponent,
		ViewerFragenNavComponent,
		ViewerFragenNavEintragComponent,
		SortableOptionsComponent,

		AusfuellerVerwaltungDialogButtonComponent,
		AusfuellerVerwaltungDialogComponent,
		AusfuellerVerwaltungFormComponent,
		CoAdminVerwaltungFormComponent,
		AbschnittsausfuellerVerwaltungFormComponent,

		ViewerAntwortAlsFertigMarkiertListeComponent,
		ViewerAntwortAlsFertigMarkierenComponent,
		ViewerAntwortAlsGeprueftMarkierenComponent,

		FragebogenausfuellerVerwaltungFormComponent,

		ViewerPortalRecherchekomplexComponent,

		ViewerFragebogenNavbarComponent,
		PasswordEingabeComponent,
		PasswortZuruecksetzenComponent,
		RegistrierungsFormularComponent,
		RegistrierungsWrapperComponent,
		UnbekannterCodeComponent,
		PasswordZuruecksetzenFormularComponent,
		PasswordStrengthComponent,
		XsrfFormFieldComponent,
		PortalSelectorComponent,
		PortalInstitutionSelectorComponent,
		PortalRecherchekomplexSelectorComponent,
		JuveAnsprechpartnerboxComponent,
		BenutzerverwaltungComponent,
		BenutzerverwaltungKarteRegistrierteAusfuellerComponent,
		BenutzerverwaltungKarteNichtRegistrierteAusfuellerComponent,
		BenutzerverwaltungRecherchekomplexComponent,
		BenutzerverwaltungFragebogenComponent,
		BenutzerverwaltungAbschnittComponent,
		BenutzerverwaltungDialogLoescheAusfuellerComponent,
		BenutzerverwaltungDialogLoescheAusfuellerFormComponent,
		PortalRecherchekomplexEintragComponent,

		SicherheitComponent,
		FaqComponent,
		TeamComponent,
		PortalComponent,
		ArchivComponent,
		PortalAllgemeinComponent,
		InitPageComponent,
		HeaderComponent,
		FooterComponent,
		ContactComponent,
		TeaserComponent,
		FormViewerWrapperComponent,
		MessageComponent,
		ForbiddenComponent,
		EditorAntwortenComponent,
		AntwortComponent,
		FormEditorVorschauComponent,
		NewTabComponent,
		RegistrierteBenutzerComponent,
		OffeneEinladungenComponent,
		RecherchekomplexeComponent,
		BenutzerBearbeitenComponent,
		RechteVerwaltenComponent,
		RecherchekomplexBenutzerComponent,
		OffeneEinladungBearbeitenComponent,
		InaktiveBenutzerComponent,
		VarDirective
	],
			  imports: [
				  BrowserModule,
				  FormsModule,
				  HttpClientModule,
				  AppRoutingModule,
				  AlertModule.forRoot(),
				  ModalModule.forRoot(),
				  PopoverModule.forRoot(),
				  TooltipModule.forRoot(),
				  BsDropdownModule.forRoot(),
				  ClipboardModule,
				  OrderListModule,
				  DropdownModule,
				  StoreModule.forRoot(reducers, {metaReducers}),
				  StoreModule.forFeature(recherchekomplexFeatureKey, recherchekomplexReducer),
				  StoreModule.forFeature(institutionFeatureKey, institutionReducer),
				  !environment.production ? StoreDevtoolsModule.instrument() : [],
				  TableModule,
				  ButtonModule,
				  BrowserAnimationsModule,
				  BrowserModule,
				  ToastModule,
				  MessagesModule,
				  MessageModule,
				  ButtonModule,
				  RippleModule,
				  FormsModule, TabViewModule,
				  CardModule,
				  PaginatorModule,
				  BadgeModule,
				  FieldsetModule,
				  CalendarModule,
				  ProgressSpinnerModule,
				  InputTextModule,
				  MultiSelectModule,
				  ReactiveFormsModule,
				  DropdownModule,
				  ToastModule,
				  MessagesModule,
				  MessageModule,
				  CheckboxModule,
				  ToggleButtonModule,
				  AvatarModule,
				  TagModule,
				  BrowserModule,
				  FormsModule,
				  HttpClientModule,
				  AppRoutingModule,
				  AlertModule.forRoot(),
				  ModalModule.forRoot(),
				  PopoverModule.forRoot(),
				  TooltipModule.forRoot(),
				  BsDropdownModule.forRoot(),
				  ClipboardModule,
				  OrderListModule,
				  DropdownModule,
				  DialogModule,
				  StoreModule.forRoot(reducers, {metaReducers}),
				  StoreModule.forFeature(recherchekomplexFeatureKey, recherchekomplexReducer),
				  StoreModule.forFeature(institutionFeatureKey, institutionReducer),
				  !environment.production ? StoreDevtoolsModule.instrument() : [],
				  InputNumberModule,
				  PortalModule,
				  FontAwesomeModule,
				  BackButtonDisableModule.forRoot({
													  preserveScrollPosition: true
												  }),
				  BlockUIModule.forRoot({
											delayStart: 0,
											delayStop: 1000
										}), AccordionModule, ConfirmDialogModule, PasswordModule, InputSwitchModule, AuthModule, StoreModule.forRoot({}, {}), StoreDevtoolsModule.instrument({
																																																 maxAge: 25,
																																																 logOnly: environment.production
																																															 }), EffectsModule.forRoot([]), KeyFilterModule],
	providers: [
		Location,
		{provide: LocationStrategy, useClass: PathLocationStrategy},
		{provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true},
		EditLockService,
		AbschnittSperreService,
		CookieService,
		MessageService,
		AlertService,
		NavigationService,
		AbschnittInfoService,
		UserService,
		RegistrierungService,
		PasswortZuruecksetzenService,
		InstitutionAntwortService,
		FragebogenStatusService,
		FragebogenDuplizierenService,
		FragebogenVeroeffentlichenService,
		AbschnittUrlHashService,
		FragebogenService,
		UnloadService,
		AbschnittEditorService,
		AbschnittStorageService,
		AbschnittReadonlyService,
		FormViewerAbschnittSperrenHelper,
		DummyFormViewerAbschnittSperrenHelper,
		AbschnittAntwortService,
		FragenSortService,
		FrageEditorService,
		CapitalizeFirst,
		PreviewService,
		FrageFertigMarkerService,
		FrageGeprueftMarkerService,
		AbschnittAusfuellerService,
		NotificationSeenService,

		AktuelleBerechtigungService,
		QueryParamsService,
		ViewerAbschnittService,

		RecherchekomplexService,
		FragebogenLoeschenService,
		AusfuellerBerechtigungsService,
		AusfuellerAbgabezeitpunktService,

		AusfuellerBenutzerdatenService,
		AusfuellerBenutzerdatenApiService,
		AusfuellerAnlegenService,
		AntwortAnsichtService,

		ContentService,
		MessageService,
		RecherchekomplexUebersichtService,
		LoggingService,
		FindAusfuellerService,
		AuthGuardEditor,
		AuthGuardViewer,
		AuthGuardViewerRK,
		{provide: LOCALE_ID, useValue: 'de'},
		{provide: HTTP_INTERCEPTORS, useClass: XhrInterceptor, multi: true}
	],
  	entryComponents: [AppComponent, PasswortZuruecksetzenComponent],
})

/* eslint-disable */
export class AppModule {
	/**
	 * Check if an Element matching a Tag-Name is present in the page
	 *
	 * @param elementName Tag-Name od the Element
	 * @returns {boolean} is it present?
	 */
	static isElementPresent(elementName: string): boolean {
		return document.getElementsByTagName(elementName).length > 0;
	}

	/**
	 * Bootstrap an optional Component if an Element is present in the page
	 *
	 * @param appRef
	 * @param elementName Tag-Name of the Element that needs to be present
	 * @param component Component to bootstrap
	 */
	static bootstrapComponentIfPresent<T>(appRef: ApplicationRef, elementName: string, component: Type<T>): void {
		if (AppModule.isElementPresent(elementName)) {
			appRef.bootstrap(component);
		}
	}

	/**
	 * Dynamic bootstrap of optional Components
	 *
	 * @param appRef
	 */
	async ngDoBootstrap(appRef: ApplicationRef) {

		AppModule.bootstrapComponentIfPresent(appRef, 'app-root', AppComponent);
		AppModule.bootstrapComponentIfPresent(appRef, 'app-form-editor', FormEditorComponent);
		AppModule.bootstrapComponentIfPresent(appRef,
			'app-editor-fragebogen-uebersicht',
			EditorFragebogenUebersichtComponent);
		AppModule.bootstrapComponentIfPresent(appRef, 'app-form-viewer', FormViewerComponent);
		AppModule.bootstrapComponentIfPresent(appRef, 'app-fragebogen-drucken', FragebogenDruckenComponent);
		AppModule.bootstrapComponentIfPresent(appRef, 'app-institutions-auswahl', InstitutionsAuswahlComponent);
		AppModule.bootstrapComponentIfPresent(appRef,
			'app-viewer-portal-recherchekomplex',
			ViewerPortalRecherchekomplexComponent);
		AppModule.bootstrapComponentIfPresent(appRef, 'app-alert', AlertComponent);
		AppModule.bootstrapComponentIfPresent(appRef, 'app-passwort-zuruecksetzen', PasswortZuruecksetzenComponent);
		AppModule.bootstrapComponentIfPresent(appRef,
			'app-passwort-zuruecksetzen-formular',
			PasswordZuruecksetzenFormularComponent);
	}
}

registerLocaleData(localeDe, 'de');
