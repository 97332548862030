import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {FindAusfuellerService} from "../../form-viewer/service/FindAusfuellerService";
import {LogEintrag} from "../model/LogEintrag";
import {AktuelleBerechtigungService} from "./AktuelleBerechtigungService";

@Injectable()
export class LoggingService {

	constructor(private http: HttpClient, private findAusfuellerService: FindAusfuellerService, private aktuelleBerechtigungService: AktuelleBerechtigungService, private router: Router) {
	}

	logError(error: HttpErrorResponse): Promise<void> {
		let logEintrag = new LogEintrag();
		logEintrag.user = this.findAusfuellerService.findAusfueller(() => null, () => null)?.id;
		logEintrag.institution = this.aktuelleBerechtigungService.getInstitutionsId();
		logEintrag.recherchekomplex = this.aktuelleBerechtigungService.getRecherchekomplexId();
		logEintrag.currentUrl = this.router.url;
		logEintrag.errorUrl = error.url;
		logEintrag.nachricht = error.message;
		logEintrag.status = error.status;

		return this.http.post('/api/logging', logEintrag)
			.toPromise()
			.then(() => {})
			.catch();
	}
}
