import {Component, Input} from '@angular/core';
import {KartenFrageEditSpalte} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/karten/spalte/KartenFrageEditSpalte';
import {InputType} from 'app/shared/model/frage/input/InputType';
import {InputTypeEmail} from "app/shared/model/frage/input/InputTypeEmail";
import {InputTypeTelefon} from "app/shared/model/frage/input/InputTypeTelefon";
import {InputTypeText} from "app/shared/model/frage/input/InputTypeText";
import {KartenFeld} from 'app/shared/model/frage/KartenFeld';

const debug = require('debug')('KartenFrageEditSpalteComponent');

@Component({
	selector: 'app-edit-karten-spalte',
	templateUrl: './KartenFrageEditSpalteComponent.html',
	styleUrls: ['./KartenFrageEditSpalteComponent.less']
})
export class KartenFrageEditSpalteComponent {
	@Input()
	spalte: KartenFrageEditSpalte;

	private static canDrag(el, container, handle) {
		return handle.classList.contains('karten-feld-drag-handle') && handle.classList.contains('draggable');
	}

	onTypeChanged(feld: KartenFeld, type: InputType) {
		if (feld.type !== type) {
			if (!(feld.type instanceof InputTypeText) || !(type instanceof InputTypeEmail || type instanceof InputTypeTelefon)) {
				debug('Löschen der Vorjahres-Referenz aufgrund von Änderung des Feld-Typs');
				feld.vorjahresfeld = null;
			}
		}

		feld.type = type;
	}
}
