import {DatePipe, Location, NgIf} from "@angular/common";
import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup, FormsModule, NgForm} from "@angular/forms";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {
	faBan,
	faExclamationTriangle,
	faFolderMinus,
	faPencilAlt,
	faPlus,
	faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {ButtonModule} from "primeng/button";
import {CardModule} from "primeng/card";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {DialogModule} from "primeng/dialog";
import {DropdownModule} from "primeng/dropdown";
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {InputSwitchModule} from "primeng/inputswitch";
import {InputTextModule} from "primeng/inputtext";
import {RippleModule} from "primeng/ripple";
import {TableModule} from "primeng/table";
import {UserService} from "../../../../form-viewer/service/UserService";
import {AlertService} from "../../../../shared/alert/service/AlertService";
import {Institution} from "../../../../shared/model/Institution";
import {Recherchekomplex} from "../../../../shared/model/Recherchekomplex";
import {VerwaltbarerAusfuellerDto} from "../../../model/VerwaltbarerAusfuellerDto";
import {VerwaltbarerAusfuellerInstitutionDto} from "../../../model/VerwaltbarerAusfuellerInstitutionDto";
import {
	VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
} from "../../../model/VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto";
import {VerwaltbarerAusfuellerRecherchekomplexDto} from "../../../model/VerwaltbarerAusfuellerRecherchekomplexDto";
import {AusfuellerService} from "../../../services/AusfuellerService";
import {EventService} from "../../../services/EventService";
import {RechteVerwaltenComponent} from "./rechte-verwalten/rechte-verwalten.component";

@Component({
	selector: "app-benutzer-bearbeiten",
	templateUrl: "./benutzer-bearbeiten.component.html",
	styleUrls: ["./benutzer-bearbeiten.component.less"],
	providers: [ConfirmationService],
	standalone: true,
	imports: [
		FormsModule,
		CardModule,
		DropdownModule,
		TableModule,
		ConfirmDialogModule,
		NgIf,
		DialogModule,
		ButtonModule,
		RippleModule,
		DatePipe,
		FontAwesomeModule,
		InputTextModule,
		InputSwitchModule,
	],
})
export class BenutzerBearbeitenComponent implements OnInit {
	public verwaltbarerAusfueller: VerwaltbarerAusfuellerDto;
	public verwaltbareInstitution: VerwaltbarerAusfuellerInstitutionDto;
	public verwaltbarerRecherchekomplex: VerwaltbarerAusfuellerRecherchekomplexDto;
	public institutionUndRecherchekomplex: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto;
	public institutionUndRecherchekomplexList: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto[];
	public selectedValue: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto;
	public anreden: any[];
	public avaliableKomplexList: Array<Recherchekomplex>;
	public newKomplex: Recherchekomplex;
	public avaliableKomplexListEmpty: boolean = true;
	public inviteAsCoAdmin: boolean = false;
	public showAddKomplexDialog: boolean = false;
	public hatBenutzerCodeNeuGeneriert = false;
	@BlockUI() blockUI: NgBlockUI;

	faPlus = faPlus;
	faTrashCan = faTrashCan;
	faBan = faBan;
	faPen = faPencilAlt;
	faFolderMinus = faFolderMinus;
	faExclamationTriangle = faExclamationTriangle;
	@ViewChild("myForm") myForm: NgForm;
	@ViewChild("addKomplexForm") addKomplexForm: NgForm;
	@Output() submitBenutzer = new EventEmitter<VerwaltbarerAusfuellerDto>();
	public myGroup: FormGroup;
	public message: string = "";
	public showMessage: boolean;
	public anyAdmin = false;
	public anyCoAdmin = false;
	public selfEditing = false;
	public formFieldDisabled = false;
	public formEmailFieldDisabled = false;
	public initDone = false;
	// baumWurdeVeraendert = new EventEmitter();
	adminEditing: boolean;
	darfFragebogenAbschliessen: boolean = false;

	// @Output()
	activeIsToggled = false;
	einladung: Boolean;
	public currentInstitute: Institution;
	public currentInstituteString: string;
	private verwaltbarerAusfuellerBackup: VerwaltbarerAusfuellerDto;

	constructor(
		public dialogService: DialogService,
		public ausfuellerService: AusfuellerService,
		public ref: DynamicDialogRef,
		public config: DynamicDialogConfig,
		private location: Location,
		private messageService: MessageService,
		private userService: UserService,
		private alertService: AlertService,
		private confirmationService: ConfirmationService,
		private eventService: EventService
	) {}

	ngOnInit() {
		this.userService.isAlive();

		this.currentInstitute = this.userService.getAktuelleInstitution();
		this.currentInstituteString =
			this.userService.getAktuelleInstitution().anzeigename;

		this.verwaltbarerAusfueller = new VerwaltbarerAusfuellerDto(
			this.config.data.verwaltbarerAusfueller
		);
		this.reloadUser(
			new VerwaltbarerAusfuellerDto(
				this.config.data.verwaltbarerAusfueller
			),
			true
		);
		this.setEinladungFlag();

		this.institutionUndRecherchekomplexList = [];
		this.institutionUndRecherchekomplexList.push(
			new VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto(
				this.verwaltbarerAusfueller
			)
		);
		this.anreden = [{ anrede: "Herr" }, { anrede: "Frau" }];

		this.verwaltbarerAusfueller.institionListAsString =
			this.userService.getAktuelleInstitution().anzeigename;

		this.eventService.userUserRightsEditListener().subscribe((info) => {
			console.log("refreshing ausfueller");
			this.reloadUser(this.verwaltbarerAusfueller, false);
		});
	}

	neuenBenutzercodeGenerieren() {
		this.confirmationService.confirm({
			message:
				'\nWollen Sie dem Benutzer "' +
				this.verwaltbarerAusfueller.anzeigename +
				'" einen neuen Registrierungscode per E-Mail zusenden?\n',
			acceptLabel: "Ja",
			rejectLabel: "Nein",
			header: "Bitte bestätigen Sie die Generierung eines neuen Registrierungscodes",
			closeOnEscape: false,
			icon: "pi pi-info-circle",
			accept: () => {
				this.ausfuellerService
					.neuenBenutzercodeGenerieren(this.verwaltbarerAusfueller)
					.then(() => {
						this.hatBenutzerCodeNeuGeneriert = true;
						this.messageService.clear();
						this.messageService.add({
							severity: "info",
							summary: "Der Benutzercode wurde neu generiert.",
						});
						this.reloadUser(this.verwaltbarerAusfueller, true);
						this.eventService.emitUserOffeneEinladungenEvent(
							"offene-einladung-bearbeiten"
						);
						this.closeDialog();
						window.setTimeout(() => {
							this.hatBenutzerCodeNeuGeneriert = false;
						}, 7500);
					});
			},
			reject: (type) => {
				switch (type) {
					case ConfirmEventType.REJECT:
						this.messageService.clear();
						this.messageService.add({
							severity: "info",
							summary: "Die Generierung wird abgebrochen.",
						});
						break;
					case ConfirmEventType.CANCEL:
						this.messageService.clear();
						this.messageService.add({
							severity: "info",
							summary: "Die Generierung wird abgebrochen.",
						});
						break;
				}
			},
		});
	}

	zugeordnetenRecherchekomplexBearbeiten(
		institutionUndRecherchekomplexDto: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto,
		newKomplex: boolean
	) {
		this.isAlive();
		if (
			institutionUndRecherchekomplexDto.recherchekomplexLabel &&
			institutionUndRecherchekomplexDto.recherchekomplexLabel.startsWith(
				"JUVE Recherche-Ansprechpartner"
			)
		) {
			this.messageService.clear();
			this.messageService.add({
				severity: "warn",
				life: 5000,
				summary:
					"Bei dieser Berechtigung handelt es sich um eine Berechtigung, die nur durch JUVE administriert werden kann.",
			});
			return;
		} else if (
			institutionUndRecherchekomplexDto.recherchekomplexLabel &&
			institutionUndRecherchekomplexDto.recherchekomplexLabel.startsWith(
				"Recherche-Koordinator"
			)
		) {
			this.messageService.clear();
			this.messageService.add({
				severity: "warn",
				life: 5000,
				summary:
					"Bei dieser Berechtigung handelt es sich um einen Recherche-Koordinator. Ein Recherche-Koordinator hat immer Rechte auf alle Fragebögen.",
			});
			return;
		}

		let found = false;
		let adminKomplexList = this.userService
			.getAusfueller()
			.getRecherchekomplexWithAdminRights(
				this.userService.getAktuelleInstitution()
			);

		this.institutionUndRecherchekomplex = institutionUndRecherchekomplexDto;
		for (const recherchekomplex of adminKomplexList) {
			if (
				this.institutionUndRecherchekomplex.rechekomplexId ==
				recherchekomplex.id
			) {
				found = true;
				break;
			}
		}

		if (!found) {
			this.messageService.clear();
			this.messageService.add({
				severity: "warn",
				life: 5000,
				summary:
					'Sie haben keine Rechte " ' +
					this.institutionUndRecherchekomplex.recherchekomplexLabel +
					'" zu bearbeiten',
			});
		} else {
			this.institutionUndRecherchekomplex =
				institutionUndRecherchekomplexDto;
			this.dialogService.open(RechteVerwaltenComponent, {
				header: "Zugeordneten Recherchekomplex bearbeiten",
				width: "75%",
				height: "100%",
				styleClass: "FullScreenDialog",
				modal: false,
				contentStyle: {
					height: "100%",
					width: "100%",
					"max-width": "100%",
					"max-height": "100%",
					overflow: "auto",
				},
				baseZIndex: 10000,
				style: { "max-width": "100%", "max-height": "100%" },
				data: {
					verwaltbarerAusfueller: this.verwaltbarerAusfueller,
					institutionUndRecherchekomplex:
						this.institutionUndRecherchekomplex,
					neueRechte: newKomplex,
				},
			});
		}
	}

	isAdminEditing() {
		//console.log("isAdminEditing")
		this.message = "";

		// this.selfEditing = this.isSelfEditing();

		if (this.selfEditing) {
			this.message = "Sie dürfen sich nicht selbst bearbeiten";
			this.adminEditing = false;
			return true;
		} else if (this.anyAdmin) {
			this.message =
				"Der aktuelle Benutzer ist ein JUVE Recherche-Ansprechpartner. Stammdaten dürfen nur durch JUVE bearbeitet werden.";
			if (
				this.verwaltbarerAusfueller.institutionenUndRechechekomplexe
					.length == 0
			) {
				this.message = this.message.concat(
					"\n Sie haben keinen Zugriff auf die Recherchekomplexe dieses Benutzers!"
				);
			} else {
				this.message = this.message.concat(
					"\n Sie können nur die Recherchekomplexe sehen, auf die Sie administrativen Zugriff haben!"
				);
			}
			this.adminEditing = true;
			return true;
		}
		// else {
		// 	this.message = "Sie können nur die Recherchekomplexe sehen, auf die Sie Zugriff haben!"
		// }

		this.adminEditing = false;
		return false;
	}

	isSelfEditing() {
		console.log("isSelfEditing");
		if (
			this.userService.getAusfueller().id ==
			this.verwaltbarerAusfueller.id
		) {
			this.message = "Sie dürfen sich nicht selbst bearbeiten";
			this.selfEditing = true;
			return true;
		}
		this.selfEditing = false;
		return false;
	}

	showSaveError() {
		this.messageService.add({
			severity: "warn",
			life: 5000,
			summary:
				'Fehler beim Speichern der Benutzerdatenänderungen für "' +
				this.verwaltbarerAusfueller.anzeigename +
				'".',
		});
	}

	showSavingMsg() {
		this.messageService.add({
			severity: "info",
			summary:
				'Die Benutzerdatenänderungen für "' +
				this.verwaltbarerAusfueller.anzeigename +
				'" werden gespeichert.',
		});
	}

	showSavedMsg() {
		this.messageService.add({
			severity: "info",
			summary:
				'Die Benutzerdatenänderungen für "' +
				this.verwaltbarerAusfueller.anzeigename +
				'" wurden gespeichert.',
		});
	}

	showSavingMsgAndMapUser(verwaltbarerAusfueller: VerwaltbarerAusfuellerDto) {
		this.verwaltbarerAusfueller = verwaltbarerAusfueller;
		this.showSavedMsg();
		this.reloadUser(this.verwaltbarerAusfueller, true);
		this.eventService.emitUserOffeneEinladungenEvent(
			"offene-einladung-bearbeiten"
		);
	}

	closeDialogAfterComplete(): void {
		this.messageService.clear();
		this.messageService.add({
			severity: "info",
			summary:
				'Die Benutzerdatenänderungen für "' +
				this.verwaltbarerAusfueller.anzeigename +
				'" wurden gespeichert.',
		});
		this.eventService.emitUserActiveToggledEventBenutzer(
			"benutzer-bearbeiten"
		);
		this.eventService.emitUserActiveToggledEventInactive(
			"benutzer-bearbeiten"
		);
		this.eventService.emitRecherchekomplexEingeladeneUserEvent(
			"benutzer-bearbeiten"
		);
		this.eventService.emitUserOffeneEinladungenEvent(
			"offene-einladung-bearbeiten"
		);

		this.ref.close();
	}

	isValidAusfueller() {
		return this.verwaltbarerAusfueller.isValid();
	}

	validateAusfueller() {
		if (!this.isValidAusfueller()) {
			this.messageService.clear();
			this.messageService.add({
				severity: "error",
				life: 8000,
				summary:
					"Alle mit einem * markierten Felder müssen mit einem gültigen Wert gefüllt sein.",
			});
			return false;
		}
		return true;
	}

	updateAusfueller() {
		this.isAlive();

		if (this.verwaltbarerAusfueller.id != null) {
			if (this.isBaseDataChanged()) this.updateAusfuellerDaten();
			else {
				this.closeDialog();
			}
		} else {
			this.createUser();
		}
	}

	updateAusfuellerDaten() {
		let dialogMsg =
			'\nWollen Sie die Benutzerdaten von "' +
			this.verwaltbarerAusfueller.anzeigename +
			'" wirklich ändern?\n';

		if (this.validateAusfueller()) {
			this.confirmationService.confirm({
				message: dialogMsg,
				acceptLabel: "Speichern",
				rejectLabel: "Abbrechen",
				header: "Bitte Speichern bestätigen",
				icon: "pi pi-info-circle",
				accept: () => {
					this.messageService.clear();
					this.ausfuellerService
						.updateAusfueller(this.verwaltbarerAusfueller)
						.subscribe(
							(newUser) => this.showSavingMsgAndMapUser(newUser),
							() => this.showSaveError(),
							() => this.closeDialogAfterComplete()
						);
				},
				reject: (type) => {
					switch (type) {
						case ConfirmEventType.REJECT:
							this.messageService.clear();
							this.messageService.add({
								severity: "info",
								summary: "Speichern wurde abgebrochen.",
							});
							break;
						case ConfirmEventType.CANCEL:
							this.messageService.clear();
							this.messageService.add({
								severity: "info",
								summary: "Speichern wurde abgebrochen.",
							});
							break;
					}
				},
			});
		}
	}

	createUser() {
		let dialogMsg =
			'\nWollen Sie den Benutzer "' +
			this.verwaltbarerAusfueller.anzeigename +
			'" mit den eingegebenen Daten anlegen?\n';

		this.messageService.clear();

		let valid = this.validateAusfueller();

		if (
			this.verwaltbarerAusfueller.institutionenUndRechechekomplexe
				.length == 0
		) {
			this.messageService.add({
				severity: "error",
				life: 8000,
				summary:
					"Ein Benutzer kann nicht ohne Rechte auf einen Recherchekomplex angelegt werden!",
			});
			this.openAddRecherchekomplexDialog();
			return;
		}

		if (valid) {
			this.confirmationService.confirm({
				message: dialogMsg,
				acceptLabel: "Speichern",
				rejectLabel: "Abbrechen",
				header: "Bitte Speichern bestätigen",
				icon: "pi pi-info-circle",
				accept: () => {
					this.messageService.clear();
					this.ausfuellerService
						.createUser(this.verwaltbarerAusfueller)
						.subscribe(
							(newUser) => this.showSavingMsgAndMapUser(newUser),
							() => this.showSaveError(),
							() => this.closeDialogAfterComplete()
						);
					//console.log('Submitted!', this.myForm);
				},
				reject: (type) => {
					switch (type) {
						case ConfirmEventType.REJECT:
							this.messageService.clear();
							this.messageService.add({
								severity: "info",
								summary: "Speichern wurde abgebrochen.",
							});
							break;
						case ConfirmEventType.CANCEL:
							this.messageService.clear();
							this.messageService.add({
								severity: "info",
								summary: "Speichern wurde abgebrochen.",
							});
							break;
					}
				},
			});
		}
	}

	createAusfuellerAndOpenRecherchekomplexDialog() {
		let dialogMsg =
			'\nWollen Sie den Benutzer "' +
			this.verwaltbarerAusfueller.anzeigename +
			'" mit den eingegebenen Daten anlegen?\n';

		if (this.validateAusfueller()) {
			// //console.log("updateAusfueller");
			this.confirmationService.confirm({
				message: dialogMsg,
				acceptLabel: "Speichern",
				rejectLabel: "Abbrechen",
				header: "Bitte Speichern bestätigen",
				icon: "pi pi-info-circle",
				accept: () => {
					this.messageService.clear();
					this.ausfuellerService
						.updateAusfueller(this.verwaltbarerAusfueller)
						.subscribe(
							(newUser) => this.showSavingMsgAndMapUser(newUser),
							() => this.showSaveError()
						);
					//console.log('Submitted!', this.myForm);
					this.eventService.emitUserActiveToggledEventBenutzer(
						"benutzer-bearbeiten"
					);
					this.eventService.emitUserActiveToggledEventInactive(
						"benutzer-bearbeiten"
					);
					this.institutionUndRecherchekomplex =
						new VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto(
							this.verwaltbarerAusfueller
						);
					this.institutionUndRecherchekomplex.setKomplexAndInstituteFieldsByInstitution(
						this.newKomplex,
						this.userService.getAktuelleInstitution()
					);
					this.zugeordnetenRecherchekomplexBearbeiten(
						this.institutionUndRecherchekomplex,
						true
					);
					this.closeAddKomplexDialog();
				},
				reject: (type) => {
					switch (type) {
						case ConfirmEventType.REJECT:
							this.messageService.clear();
							this.messageService.add({
								severity: "info",
								summary: "Speichern wurde abgebrochen.",
							});
							// 					this.verwaltbarerAusfueller = null;
							break;
						case ConfirmEventType.CANCEL:
							this.messageService.clear();
							this.messageService.add({
								severity: "info",
								summary: "Speichern wurde abgebrochen.",
							});
							// 					this.verwaltbarerAusfueller = null;
							break;
					}
				},
			});
		}
	}

	sendTempPwd(): void {
		let dialogMsg =
			'\nWollen Sie dem Benutzer "' +
			this.verwaltbarerAusfueller.anzeigename +
			'" ein neues temporäres Passwort senden?\n';

		if (this.validateAusfueller()) {
			this.confirmationService.confirm({
				message: dialogMsg,
				acceptLabel: "Speichern",
				rejectLabel: "Abbrechen",
				header: "Bitte Speichern bestätigen",
				icon: "pi pi-info-circle",
				accept: () => {
					this.messageService.clear();
					this.sendTempPwdConfirmed();
				},
				reject: (type) => {
					switch (type) {
						case ConfirmEventType.REJECT:
							this.messageService.clear();
							this.messageService.add({
								severity: "info",
								summary: "Aktion wurde abgebrochen.",
							});
							break;
						case ConfirmEventType.CANCEL:
							this.messageService.clear();
							this.messageService.add({
								severity: "info",
								summary: "Aktion wurde abgebrochen.",
							});
							break;
					}
				},
			});
		}
	}

	sendTempPwdConfirmed(): void {
		this.ausfuellerService.sendTempPwd(this.verwaltbarerAusfueller);
	}

	closeDialog(): void {
		this.isAlive();
		this.messageService.clear();
		this.ref.close();
		//console.log("closeDialog")
	}

	toggleActive() {
		//console.log("toggleActive()")
		this.activeIsToggled = !this.activeIsToggled;
	}

	collectAvaliableKomplexList() {
		console.log("collectAvaliableKomplexList");
		//console.log(this.userService.getAusfueller())
		let adminKomplexList = this.userService
			.getAusfueller()
			.getRecherchekomplexWithAdminRights(
				this.userService.getAktuelleInstitution()
			);
		console.log(this.userService.getAusfueller());
		//console.log(adminKomplexList)
		let found;

		this.avaliableKomplexList = new Array<Recherchekomplex>();

		//console.log(adminKomplexList)

		let komplexIds = new Set();

		for (const recherchekomplex of adminKomplexList) {
			found = false;
			for (const verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto of this
				.verwaltbarerAusfueller.institutionenUndRechechekomplexe) {
				if (
					recherchekomplex.id ==
					verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto.rechekomplexId
				) {
					found = true;
					break;
				}
			}

			if (!found) {
				if (!komplexIds.has(recherchekomplex.id)) {
					komplexIds.add(recherchekomplex.id);
					this.avaliableKomplexList.push(recherchekomplex);
					// console.log(recherchekomplex)
				} else {
					// console.log("KOMPLEX IST BEREITS IN DER LISTE: " + recherchekomplex)
				}
			}
		}
		this.avaliableKomplexList.sort((a, b) =>
			a.name.toUpperCase().localeCompare(b.name.toUpperCase())
		);
		this.avaliableKomplexListEmpty = this.avaliableKomplexList.length == 0;
		console.log("collectAvaliableKomplexList...DONE");
		// console.log(this.avaliableKomplexList)
	}

	openAddRecherchekomplexDialog() {
		if (this.validateAusfueller()) {
			this.inviteAsCoAdmin = false;
			this.newKomplex = null;
			this.showAddKomplexDialog = true;
			this.collectAvaliableKomplexList();
		}
	}

	closeAddKomplexDialog() {
		this.isAlive();

		this.messageService.clear();
		this.showAddKomplexDialog = false;
		this.collectAvaliableKomplexList();
		//console.log("closeAddKomplexDialog")
	}

	addRecherchekomplex() {
		this.isAlive();

		//console.log("addRecherchekomplex")
		if (this.newKomplex) {
			if (this.validateAusfueller()) {
				if (this.verwaltbarerAusfueller.id == null) {
					if (this.inviteAsCoAdmin)
						this.createAusfuellerAndInviteUserAsCoAdmin();
					else {
						this.openRechteverwaltung();
					}
				} else {
					if (this.inviteAsCoAdmin) this.inviteUserAsCoAdmin();
					else {
						this.openRechteverwaltung();
					}
				}
			} else {
				this.messageService.clear();
				this.messageService.add({
					severity: "warn",
					life: 8000,
					summary: "User ist nicht vollständig",
				});
			}
		} else {
			this.messageService.clear();
			this.messageService.add({
				severity: "error",
				life: 8000,
				summary: "Bitte wählen sie einen Recherchekomplex aus!",
			});
		}
	}

	recherchekomplexRechtZurueckziehenConfirm(
		institutionUndRecherchekomplexDto: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
	) {
		this.isAlive();

		if (
			institutionUndRecherchekomplexDto.recherchekomplexLabel &&
			institutionUndRecherchekomplexDto.recherchekomplexLabel.startsWith(
				"JUVE Recherche-Ansprechpartner"
			)
		) {
			this.messageService.clear();
			this.messageService.add({
				severity: "warn",
				life: 5000,
				summary:
					"Bei dieser Berechtigung handelt es sich um eine Berechtigung, die nur durch JUVE administriert werden kann.",
			});
			return;
		}

		let dialogMsg =
			'Wollen Sie dem Benutzer "' +
			this.verwaltbarerAusfueller.anzeigename +
			'" alle Rechte auf den Recherchekomplex "' +
			institutionUndRecherchekomplexDto.recherchekomplexName +
			'" entziehen?\n';

		this.confirmationService.confirm({
			message: dialogMsg,
			acceptLabel: "Speichern",
			rejectLabel: "Abbrechen",
			header: "Bitte Speichern bestätigen",
			icon: "pi pi-info-circle",
			accept: () => {
				this.messageService.clear();
				this.recherchekomplexRechtZurueckziehen(
					institutionUndRecherchekomplexDto
				);
				//console.log('Submitted!', this.myForm);
				this.eventService.emitUserActiveToggledEventBenutzer(
					"benutzer-bearbeiten"
				);
				this.eventService.emitUserActiveToggledEventInactive(
					"benutzer-bearbeiten"
				);
				this.closeAddKomplexDialog();
			},
			reject: (type) => {
				switch (type) {
					case ConfirmEventType.REJECT:
						this.messageService.clear();
						this.messageService.add({
							severity: "info",
							summary: "Speichern wurde abgebrochen.",
						});
						break;
					case ConfirmEventType.CANCEL:
						this.messageService.clear();
						this.messageService.add({
							severity: "info",
							summary: "Speichern wurde abgebrochen.",
						});
						break;
				}
			},
		});
	}

	recherchekomplexRechtZurueckziehen(
		institutionUndRecherchekomplexDto: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
	) {
		//console.log("recherchekomplexRechtZurueckziehen")
		this.ausfuellerService
			.recherchekomplexRechtZurueckziehen(
				this.verwaltbarerAusfueller,
				institutionUndRecherchekomplexDto.institutionId,
				institutionUndRecherchekomplexDto.rechekomplexId
			)
			.then(() => {
				this.reloadUser(this.verwaltbarerAusfueller, true);
			});
	}

	reloadUser(
		verwaltbarerAusfueller: VerwaltbarerAusfuellerDto,
		tabRefresh: boolean
	) {
		console.log("reloadUser");
		this.blockUI.start("Daten werden verarbeitet...");

		if (verwaltbarerAusfueller.id) {
			this.ausfuellerService
				.getAusfuellerById(verwaltbarerAusfueller)
				.then((returnVal) => {
					this.verwaltbarerAusfueller = new VerwaltbarerAusfuellerDto(
						returnVal
					);
					this.initAusfueller();
					if (tabRefresh) {
						this.emitTabRefresh();
					}
					this.verwaltbarerAusfueller.institionListAsString =
						this.userService.getAktuelleInstitution().anzeigename;
					this.initDone = true;
					this.blockUI.stop();
				});
		} else if (
			verwaltbarerAusfueller.email &&
			verwaltbarerAusfueller.email !== ""
		) {
			this.ausfuellerService
				.getAusfuellerByMail(verwaltbarerAusfueller)
				.then((returnVal) => {
					this.verwaltbarerAusfueller = new VerwaltbarerAusfuellerDto(
						returnVal
					);
					this.initAusfueller();
					if (tabRefresh) {
						this.emitTabRefresh();
					}
					this.initDone = true;
					this.verwaltbarerAusfueller.institionListAsString =
						this.userService.getAktuelleInstitution().anzeigename;
					this.blockUI.stop();
				});
		} else {
			this.collectAvaliableKomplexList();
			this.initDone = true;
			this.blockUI.stop();
		}
	}

	isAlive() {
		this.userService.isAlive();
	}

	private initAusfueller() {
		this.selfEditing = this.isSelfEditing();

		this.ausfuellerService
			.isAnyAdmin(this.verwaltbarerAusfueller)
			.then((returnVal) => {
				this.anyAdmin = returnVal;
				// console.log(this.anyAdmin)
				this.isAdminEditing();
				this.formFieldDisabled = this.adminEditing || this.selfEditing;
				this.formEmailFieldDisabled =
					this.adminEditing || this.anyAdmin || this.selfEditing;
			});

		let inst = this.userService.getAktuelleInstitution();

		let tmpList: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto[] =
			[];

		for (const verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto of this
			.verwaltbarerAusfueller.institutionenUndRechechekomplexe) {
			if (
				inst.id ===
				verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto.institutionId
			)
				tmpList.push(
					verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
				);
		}

		this.verwaltbarerAusfueller.institutionenUndRechechekomplexe = tmpList;
		this.collectAvaliableKomplexList();

		this.verwaltbarerAusfuellerBackup = JSON.parse(
			JSON.stringify(this.verwaltbarerAusfueller)
		);
	}

	private setEinladungFlag() {
		let tmp = new Boolean(this.config.data.einladung);
		this.einladung = tmp == true;
		//console.log("this.einladung: " + this.einladung)
	}

	private emitTabRefresh() {
		if (this.einladung)
			this.eventService.emitUserOffeneEinladungenEvent(
				"offene-einladung-bearbeiten"
			);
		else
			this.eventService.emitUserActiveToggledEventInactive(
				"benutzer-bearbeiten"
			);
	}

	private openRechteverwaltung() {
		this.institutionUndRecherchekomplex =
			new VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto(
				this.verwaltbarerAusfueller
			);
		this.institutionUndRecherchekomplex.setKomplexAndInstituteFieldsByInstitution(
			this.newKomplex,
			this.userService.getAktuelleInstitution()
		);
		this.zugeordnetenRecherchekomplexBearbeiten(
			this.institutionUndRecherchekomplex,
			true
		);
		this.closeAddKomplexDialog();
	}

	private createAusfuellerAndInviteUserAsCoAdmin() {
		let dialogMsg =
			'\nWollen Sie den Benutzer "' +
			this.verwaltbarerAusfueller.anzeigename +
			'" speichern und ihm Recherche-Koordinator Rechte auf den Recherchekomplex "' +
			this.newKomplex.name +
			'" geben?\n';

		if (this.validateAusfueller()) {
			// //console.log("updateAusfueller");
			this.confirmationService.confirm({
				message: dialogMsg,
				acceptLabel: "Speichern",
				rejectLabel: "Abbrechen",
				header: "Bitte Speichern bestätigen",
				icon: "pi pi-info-circle",
				accept: () => {
					this.messageService.clear();
					this.ausfuellerService
						.createAndInviteAsCoAdmin(
							this.verwaltbarerAusfueller,
							this.newKomplex.id.toString(),
							this.darfFragebogenAbschliessen
						)
						.subscribe(
							(newUser) => this.showSavingMsgAndMapUser(newUser),
							() => this.showSaveError()
						);
					//console.log('Submitted!', this.myForm);
					this.eventService.emitUserActiveToggledEventBenutzer(
						"benutzer-bearbeiten"
					);
					this.eventService.emitUserActiveToggledEventInactive(
						"benutzer-bearbeiten"
					);
					this.eventService.emitUserOffeneEinladungenEvent(
						"offene-einladung-bearbeiten"
					);

					this.closeAddKomplexDialog();
				},
				reject: (type) => {
					switch (type) {
						case ConfirmEventType.REJECT:
							this.messageService.clear();
							this.messageService.add({
								severity: "info",
								summary: "Speichern wurde abgebrochen.",
							});
							break;
						case ConfirmEventType.CANCEL:
							this.messageService.clear();
							this.messageService.add({
								severity: "info",
								summary: "Speichern wurde abgebrochen.",
							});
							break;
					}
				},
			});
		}
	}

	private inviteUserAsCoAdmin() {
		let dialogMsg =
			'\nWollen Sie dem Benutzer "' +
			this.verwaltbarerAusfueller.anzeigename +
			'" Recherche-Koordinator Rechte auf den Recherchekomplex "' +
			this.newKomplex.name +
			'" geben?\n';

		if (this.validateAusfueller()) {
			// //console.log("updateAusfueller");
			this.confirmationService.confirm({
				message: dialogMsg,
				acceptLabel: "Speichern",
				rejectLabel: "Abbrechen",
				header: "Bitte Speichern bestätigen",
				icon: "pi pi-info-circle",
				accept: () => {
					this.messageService.clear();
					this.ausfuellerService
						.inviteAsCoAdmin(
							this.verwaltbarerAusfueller.id,
							this.newKomplex.id.toString(),
							this.userService.getAktuelleInstitution().id,
							this.darfFragebogenAbschliessen
						)
						.subscribe(
							(newUser) => this.showSavingMsgAndMapUser(newUser),
							() => this.showSaveError()
						);
					//console.log('Submitted!', this.myForm);
					this.eventService.emitUserActiveToggledEventBenutzer(
						"benutzer-bearbeiten"
					);
					// this.eventService.emitUserActiveToggledEventInactive("benutzer-bearbeiten");
					this.reloadUser(this.verwaltbarerAusfueller, true);
					this.closeAddKomplexDialog();
				},
				reject: (type) => {
					switch (type) {
						case ConfirmEventType.REJECT:
							this.messageService.clear();
							this.messageService.add({
								severity: "info",
								summary: "Speichern wurde abgebrochen.",
							});
							break;
						case ConfirmEventType.CANCEL:
							this.messageService.clear();
							this.messageService.add({
								severity: "info",
								summary: "Speichern wurde abgebrochen.",
							});
							break;
					}
				},
			});
		}
	}

	private isBaseDataChanged() {
		return (
			this.verwaltbarerAusfueller.anrede !=
				this.verwaltbarerAusfuellerBackup.anrede ||
			this.verwaltbarerAusfueller.email !=
				this.verwaltbarerAusfuellerBackup.email ||
			this.verwaltbarerAusfueller.anzeigename !=
				this.verwaltbarerAusfuellerBackup.anzeigename
		);
	}
}
