import {Injectable} from '@angular/core';
import {Ausfueller} from 'app/form-viewer/model/Ausfueller';

@Injectable()
export class FindAusfuellerService {

	private ausfueller: Ausfueller;
	private ausfuellerItem: string;

	constructor() {}

	findAusfueller(noUserFound: (skipRediredt: any) => Ausfueller, clearData: () => void): Ausfueller {

		if (!this.ausfueller) {
			console.log('Find Ausfueller');

			try {
				this.ausfuellerItem = localStorage.getItem('ausfueller');
				if (this.ausfuellerItem && this.ausfuellerItem.trim()) {
					this.ausfuellerItem = this.decodeB64(this.ausfuellerItem);
					this.ausfueller = new Ausfueller(JSON.parse(this.ausfuellerItem));
					if (this.ausfueller == null) {
						return noUserFound(false);
					}
				} else {
					return noUserFound(false);
				}
			} catch (exception) {
				console.clear();
				console.log(exception);
				console.log('Ihre Benutzerdaten konnten nicht fehlerfrei geladen werden. ' +
								'Bitte führen Sie folgende Schritte aus und kontaktieren Sie einen Adminstrator: \n\n' +
								'1. F12 drücken\n' +
								'2. Console öffnen\n' +
								'3. Kopieren Sie alle Ausgaben der Konsole und leiten Sie diese als Text-Datei an einen Administrator weiter');

				console.log('ADMINLOG-START -->');
				console.log(this.ausfuellerItem);
				console.log('<--ADMINLOG-END');
				clearData();
				return null;
			}
		}
		return this.ausfueller;

	}

	decodeB64(str): string {
		return decodeURIComponent(escape(atob(str)));
	}

}


